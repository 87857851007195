.chart_container {
  position: relative;
}

.legend_container {
  top: 0;
  border-bottom: 1px solid #F1F2F4;
  width: 100%;

  .legend {
    margin-top: 100px;
  }
}
