.page_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.page_title {
  font-weight: 500;
  text-transform: uppercase;
  color: #29394d;
  font-size: 18px;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: .5rem;
}

.page_subtitle {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6d7f92;
  margin-bottom: .5rem;
}

.main_container {
  display: flex;
  gap: 2rem;
  margin-top: 16px;
}

.months_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  width: 66%;
}

//============= CATEGORIES SECTION STYLES
.categories_container {
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #f1f2f4;
  min-height: 550px;
  max-height: 650px;
  overflow: hidden;
}

// ============= HOLIDAYS LIST SECTION STYLES
.holidays_list {
  padding: 0 20px;
  overflow-y: scroll;

  .holidays_list_month {
    margin: 12px 0;
  }

  .month_label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6d7f92;
    margin-left: 5px;
  }
}
