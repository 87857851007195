.setting-bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  z-index: 5;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #f8f9fa;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: nth($transitions, 5);
    cursor: pointer;
    position: relative;
    color: #29394D;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 1rem 0 0.3rem;
    }

    span,
    div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    svg {
      fill: #29394D;
      width: 26px;
      height: 24px;
    }

    &.send {
      svg {
        fill: #ffffff;
      }
    }

    &.circleSvg {
      svg {
        fill: none;

        path {
          fill: #29394D;
        }

        circle {
          stroke: #29394D;
        }
      }

      &:hover,
      &.active {
        svg {
          fill: none;

          path {
            fill: #ffffff;
          }

          circle {
            stroke: #ffffff;
          }
        }
      }
    }

    &.noFill {
      svg {
        fill: none;

        path {
          stroke: #29394D;
        }
      }

      &:hover,
      &.active {
        svg {
          fill: none;

          path {
            stroke: #ffffff;
          }

          circle {
            stroke: #ffffff;
          }
        }
      }
    }

    &:hover,
    &.active {
      transform: translate(-120px);
      background: #2495e1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;

      span,
      div {
        opacity: 1;
      }

      svg {
        fill: #ffffff;
      }

      .padding-left {
        padding-left: 1rem;
      }
    }

    &.opened {
      transform: translate(-650px);
    }

    &.openedExtraSlideSideBar {
      transform: translate(-1050px);
    }

    &.openedAddAnnexeSideBar {
      transform: translate(-950px);
    }

    &.openedAssociationSideBar {
      transform: translate(-67.5vw);
    }

    &.success {
      background: #06d9b1;
      color: #fff;
    }

    &.info {
      background: #f1f2f4;
      color: #3c4e64;

      span {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  &.associate-bubbles {
    & > div {
      background: #29394d;
      color: #c7e8fe;
      svg {
        fill: #c7e8fe;
      }

      &.circleSvg {
        svg {
          fill: none;

          path {
            fill: #c7e8fe;
          }

          circle {
            stroke: #c7e8fe;
          }
        }
      }

      &:hover,
      &.active {
        background: #29394d;
        color: #c7e8fe;
        svg {
          fill: #c7e8fe;
        }
      }
    }
  }
}

.bubbles-sticky {
  right: calc(80% - 125px);
}

.bubbles_behaviour {
  & > div {
    background: #ffffff;

    &:hover,
    &.active {
      background: #2495E1;
      width: 220px;
    }
  }
}
