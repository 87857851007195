@use "../../../../styles/base/variables";

.bar {
  background-color: variables.$white;
  width: 100%;
  height: 45px;
  border: 1px solid variables.$athens-gray;
  border-radius: 5px;
  overflow: hidden;

  &_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: all 1s ease;
    padding: 7px 0;

    & > div {
      display: flex;
      gap: 100px;
      animation: scrollText 60s infinite linear;
    }

    &_item {
      display: flex;
      align-items: center;
      gap: 10px;

      &_label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        &_name,
        &_since {
          color: variables.$level-0-color;
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &_since {
          color: variables.$slate-gray;
          font-size: 10px;
        }
      }

      &_separator {
        width: 1px;
        height: 100%;
        background-color: variables.$level-0-color;
        margin-right: 10px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          height: 0;
          width: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 10px solid variables.$level-0-color;
        }
      }

      &_details {
        display: flex;
        align-items: center;
        gap: 10px;
        color: variables.$slate-gray;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          white-space: nowrap;
          .duration_value {
            color: variables.$level-0-color;
          }
        }

        .dot {
          width: 4px;
          height: 4px;
          background-color: variables.$cadet-blue;
          border-radius: 50%;
        }
      }
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }

  .placeholder_item {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 500px 100%;
    animation: shimmer 3.5s infinite linear;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
