.card_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #29394D;
  background: #FFF;
  padding: 20px;

  .row_1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .percentage {
      font-size: 24px;
      font-weight: 700;
    }

    .amount {
      display: flex;
      padding: 3px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      border: 1px solid #E1E4E8;
      background: #FFF;

      .status_circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  .row_2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #29394D;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .row_3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .details_boxes {
      width: 100%;
      display: flex;
      gap: 20px;
    }

    .details_box {
      width: 100%;
      display: flex;
      padding: 10px 6px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3px;
      align-self: stretch;
      border-radius: 5px;

      .value {
        color: #29394D;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }

      .label {
        color: #29394D;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
      }

      .description {
        color: #DE4848;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}


.card_header.positif {
  .percentage {
    color: #02AF8E;
  }

  .amount {
    .status_circle {
      background-color: #02AF8E;
    }
  }
}

.card_header.negatif {
  .percentage {
    color: #DE4848;
  }

  .amount {
    color: #B2BCC6;
    .status_circle {
      background-color: #DE4848;
    }
  }
}

.details_box.positif {
  background-color: #EDFAF5;
}

.details_box.negatif {
  background-color: #FDEFEF;
}
