.content-card {
    display: flex;
    padding: 20px 20px 40px 20px;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;

    border-radius: 5px;
    border: 1px solid var(--gris-3, #F1F2F4);
    background: var(--blanc, #FFF);
    
    /* shadow bouton */
    box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.10);
    transition: 0.2s ease-in-out;

    &:hover{
      border-radius: 5px;
      border: 1px solid var(--bleu-1-principale, #18A0FB);
      background: var(--blanc, #FFF);
      cursor: pointer;

      .content-card__action-btn {
        border-radius: 100px;
        background: var(--bleu-1-principale, #18A0FB);
        & svg path[fill="#29394D"] {
          fill: white !important;
          cursor: pointer;
        }
  
        &__svg {
          width: 16px;
          height: 16px;
        }
      }
    }


    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    &__text-content{
      align-self: stretch;
      color: var(--dark-1, #29394D);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    
    &__label {
      color: var(--Bleu-1, #18A0FB);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.32px;
      text-transform: uppercase;
    }



    &__action-btn {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--gris-3, #F1F2F4);
      border: none;
      transition: background 0.2s ease-in-out;

      &:hover {
        border-radius: 100px;
        background: var(--bleu-1-principale, #18A0FB);
        & svg path[fill="#29394D"] {
          fill: white !important;
          cursor: pointer;
        }
      }

      &__svg {
        width: 16px;
        height: 16px;
      }
    }

    




  }