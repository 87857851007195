.portfolio_container {
  .container {
    padding: 0px;
  }
}

.percentage {
  &__positive {
    font-size: 14px;
    color: #18A0FB;
  }

  &__negative {
    font-size: 14px;
    color: #e4525d;
  }
}

.no_data {
  text-align: center;
  margin: 4rem 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 63vh;
  font-family: Roboto;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}

.timesheet-progression-table {
  .timesheet-table-title {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    width: 100%;
  }

  .balance-sheet-key-indicator-treasury-bold {
    padding: 8px 16px;
  }

  .timesheet-table-client-type-count-badge {
    margin-right: 12px;
    background: #F8F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 2px 4px;
    min-width: 50px;
    border-radius: 5px;
    color: #6d7f92;
  }

  .timesheet-table-client-role-badge {
    margin-left: 10px;
    margin-right: 12px;
    padding: 2px 6px;
    background: #F8F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 5px;
    color: #6d7f92;
    min-width: auto;
    text-wrap: nowrap;
  }

  .ant-table-row-level-0 .ant-table-row-expand-icon {
    margin: 0 !important;
  }

  .ant-table-row-level-1 .ant-table-row-expand-icon {
    margin: 0 !important;
  }

  .ant-table-row-level-2 .ant-table-row-expand-icon {
    margin: 0 !important;
  }

  .ant-table-row-expand-icon-collapsed,
  .ant-table-row-expand-icon-expanded {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .timesheet-table-client-type-padding {
    padding-left: 12px;
  }
  .timesheet-table-client-name-padding {
    padding-left: 24px;
  }
  .timesheet-table-category-padding {
    padding-left: 32px;
  }

  .timesheet-table-balance-is-negative {
    color: #e4525d;
  }

  .ant-table-row.ant-table-row-level-0 {
    .ant-table-cell:first-of-type {
      background-color: #e5e8eb;
    }
  }

  .ant-table-row.ant-table-row-level-1 {
    .ant-table-cell:first-of-type {
      background-color: #c9d0d7;
      color: #29394d;
      font-weight: 600;
    }
  }

  .ant-table-row.ant-table-row-level-2 {
    .ant-table-cell {
      background-color: #e4e8eb;
      color: #29394d;
    }
  }
  .ant-table-row.ant-table-row-level-3 {
    .ant-table-cell {
      background-color: #f5f6f7;
      color: #29394d;
    }
  }
}
