.bonus_custom_table {
  font-size: 10px !important;
  width: 100%;
  border: 0;

  .ant-table-cell {
    background-color: #fff;
    color: #000;
    border-radius: 0;
    color: #29394D;
    font-size: 12px;
    font-weight: 400;
    min-height: 34px;
  }

  .ant-table-thead {
    th.ant-table-cell {
      color: #29394D;
      font-size: 12px;
      font-weight: 500;
      border-bottom: 1px solid #29394D;
      padding-bottom: 10px;
      min-height: 34px;
      background-color: #fff;
      padding: 0;
      text-transform: none;
    }

    tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: #fff;
    }
  }

  .ant-table-tbody .ant-table-cell {
    background-color: #fff;
    border-radius: 0;
    padding: 6px 0 !important;
    color: #29394D;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7;
    border: 0;
    border-bottom: 1px solid #E1E4E8;
  }

  td.table-cell-bold {
    font-weight: 500 !important;
    text-transform: uppercase;
  }
}

.deposit_docs_by_client_table {
  th.ant-table-cell {
    line-height: 1rem;
  }

  .ant-table-summary {
    .ant-table-cell {
      border-top: 1px solid #f1f1f2;
      text-align: center;
    }
  }
}

.bonus_custom_table_pagination {
  color: #29394D;
  font-size: 12px;
  font-weight: 500;
  margin: 0;

  &.ant-table-pagination.ant-pagination {
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .ant-pagination-total-text {
    line-height: 30px !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    height: 32px;
  }

  .ant-pagination-item,
  .ant-pagination-options,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    display: none;
  }

  .ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    margin-right: 0;
  }

  .pagination_button {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #E1E4E8;
    background: #FFF;

    &:first-of-type {
      margin-left: 8px;
    }

    &[disabled] {
      border: 1px solid #E1E4E8;
      opacity: 0.5;
      background: #FFF;
    }
  }
}
