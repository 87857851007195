.filter_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E1E4E8;
}

.period_selector {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #6D7F92;
  text-transform: uppercase;

  border-radius: 30px;
  background: #F1F2F4;

  .period_selector_item {
    width: 120px;
    height: 100%;
    background: #F1F2F4;
    border-radius: 30px;
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &:hover {
      background: #E1E4E8;
    }
    .span {
      width: 22px;
      height: 16px;
      color: #6D7F92;
      background: #FFF;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 500;
      padding: 1px 4px;
    }

    &_active {
      color: #F1F2F4;
      background: #6D7F92!important;
    }
  }
}

.sub_period_selector_items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  .item_tag {
    height: 26px;
    border-radius: 5px;
    min-width: 51px;
    padding: 5px 7px;
    background: #F1F2F4;
    color: #6D7F92;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }
  .min-tag{
    width: 26px;
    min-width: 26px;
  }
  .item_active {
    color: #F1F2F4;
    background: #6D7F92!important;
  }
  .item_disabled {
    opacity: 0.4;
    cursor:not-allowed;
  }
}
