.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  width: 468px;
  height: 100%;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 2px 10px 0px rgba(41, 57, 77, 0.1);
  overflow-y: scroll;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .title {
    color: #29394D;
    font-weight: 500;
    font-size: 24px;
    text-transform: capitalize;
  }

  .close_icon {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .check_item {
    padding: 12px 0.5rem;
    cursor: pointer;
  }

  .check_title {
    display: flex;
    gap: 8px;
    align-items: center;

    .check_label {
      color: #29394D;
      font-size: 0.9rem;
    }

    .check_info_icon {
      margin-left: auto;
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background-color: #F1F2F4;
    }
  }

  .realized_values {
    color: #3c4e64;
    font-size: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    .realized_title {
      color: #6D7F92;
      font-size: 12px;
      font-weight: 500;
    }

    .realized_per_week,
    .realized_per_month {
      color: #29394D;
      font-size: 12px;
      font-weight: 500;
    }

    .realized_title {
      margin-right: 8px;
    }

    .realized_per_week::after {
      content: '|';
      margin: 6px;
    }
  }

  .check_description {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgb(241, 242, 244);
    color: #3c4e64;
    font-size: 12px;
    transition: all 1s ease-in-out;
  }

  .total_remark_text {
    display: flex;
    padding: 10px 30px;
    align-items: flex-start;
    gap: 10px;
    font-size: 10px;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    gap: 3px;
    background-color: #F8F9FA;
    color: #29394D;
    font-size: 12px;
    font-weight: 400;

    span {
      color:#DE4848
    }
  }
}
