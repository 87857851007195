.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content-cards {
  flex-direction: column;
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.card_fetching {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 19%;
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  border-radius: 5px;
  margin: 0px 5px 10px;
  height: fit-content;
  padding: 20px 18px;
  width: 254px;

  .avatar {
    width: 65px;
    height: 65px;
    margin: auto;
    border: 1px white solid;
    border-radius: 50%;
  }

  .title {
    height: 19px;
    width: 160px;
    margin: 10px auto;
  }

  .progress {
    height: 28px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 11px;
    border-bottom: 1px solid #E1E4E8;
    width: 100%;

    span {
      width: 40px;
      height: 18px;
      &:first-child {
        width: 126px;
      }

      &:nth-child(2) {
        font-weight: 700;
        font-size: 16px;
        color: #29394D;
      }
    }
  }

  .counters {
    display: flex;
    height: 60px;
    .counter {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;

      span {
        height: 18px;
        width: 84px;

        &:first-child {
          width: 30px;
          margin-bottom: 10px;
        }
      }
      &:first-child {
        border-right: 1px solid #E1E4E8;
      }
    }
  }
}
.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}

.filter_custom {
  display: flex;
  justify-content: space-between;
  width: 636px;

  .mini-tabs-frame {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #6D7F92;
    text-transform: uppercase;

    border-radius: 30px;
    background: #F1F2F4;

    > div {
      width: 120px;
      height: 100%;
      background: #F1F2F4;
      border-radius: 30px;
      padding: 8px 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;

      &:hover {
        background: #E1E4E8;
      }
      .span {
        width: 22px;
        height: 16px;
        color: #6D7F92;
        background: #FFF;
        border-radius: 50px;
        font-size: 12px;
        font-weight: 500;
        padding: 1px 4px;
      }
    }

    &_active {
      color: #F1F2F4;
      background: #6D7F92!important;

    }
  }
}
