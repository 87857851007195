.custom_day_picker {
  .rdp-months {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rdp-month {
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    font-size: 0.8rem;
  }

  .rdp-caption {
    display: none;
  }

  .rdp-head {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 35px;
      right: 7%;
      width: 86%;
      margin: 0;
      border-bottom: 1px solid #f1f2f4;
    }

    .rdp-head_cell {
      color: #6d7f92;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
    }
  }

  .rdp-row:last-of-type {
    .rdp-cell {
      padding-bottom: 10px;
    }
  }

  // reset opacity of all buttons
  .rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: 1;
  }

  .rdp-day {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #29394d;
    padding: 12px;
    position: relative;
    border-radius: 0;

    &::after {
      content: "";
      position: absolute;
      top: 31px;
      left: 50%;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #02af8e;
      transform: translateX(-50%);
    }

    &.rdp-day_outside,
    &.holiday_day,
    &.annual_holiday_day,
    &.weekend_day {
      color:rgba(0, 0, 0, 0.2);
    }

    &.holiday_day {
      &::after {
        background-color: rgb(255, 99, 110);
      }
    }

    &.annual_holiday_day {
      &::after {
        background-color: #f7953e;
      }
    }

    &.absence_day {
      &::after {
        width: 16px;
        height: 5px;
        border-radius: 0%;
        background-color: rgb(70, 149, 219);
      }
    }

    &.other_day {
      &::after {
        width: 16px;
        height: 5px;
        border-radius: 0%;
        background-color: rgb(121, 106, 217);
      }
    }

    &.partial_day {
      &::after {
        width: 16px;
        height: 5px;
        border-radius: 0%;
        background-color: rgb(209, 52, 51);
      }
    }

    &.weekend_day,
    &.rdp-day_outside {
      &::after {
        background-color: transparent;
      }
    }
  }

  .rdp-day_selected {
    background-color: #6d7f92;
    color: #fff;
    border-radius: 0%;

    &::after {
      display: none;
    }
  }

  .absence_day_start {
    border-top-left-radius: 50%;
    // border-bottom-left-radius: 50%;
  }

  .absence_day_end {
    // border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}
