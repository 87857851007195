.balances_recap_page {
  .page-content {
    padding: 20px;
  }

  .echeance_container {
    width: 100%;
    font-family: Arial, sans-serif;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 5px;
  }

  th,
  td {
    padding: 5px;
    border-radius: 8px;
  }

  th {
    background-color: #3c4e64;
    color: white;
    font-weight: bold;
    text-align: left;
  }

  td {
    background-color: #fefcfa;
    font-family: Roboto, "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #29394d;
  }

  .tod_content {
    justify-content: center;
    display: flex;
  }
  .week-row {
    background-color: #f1f2f4 !important;
    text-align: left;
    button {
      display: flex;
      align-items: center;
      & div {
        color: #6d7f92;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-left: 20px;
      }
    }
  }

  .collaborator-row:hover {
    background-color: #d3d3d3;
  }

  .collaborator-row td:first-of-type {
    padding-left: 15px;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    margin: 0;
    padding: 0 5px;
    list-style-type: circle;
  }

  button:focus {
    outline: none;
  }
}
