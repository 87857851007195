.card_body {
  width: 100%;
}

.title {
  color: #29394D;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0;
}

.body_content {

}
