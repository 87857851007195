.holidays_list_item {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 5px auto;
  padding: 8px;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  cursor: default;
  position: relative;
  transition: 300ms;

  &_type_circle {
    width: 5px;
    height: 5px;
    display: block;
    margin-right: 10px;
    margin-left: 4px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &_label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #6d7f92;

    &_day_count {
      font-weight: 500;
      color: #29394d;
    }
  }

  &_status_tag {
    font-size: 9px;
    margin-left: 1rem;
    text-transform: uppercase;
  }

  &_attachments_tag,
  &_binome_tag {
    padding: 5px 6px;
    font-size: 9px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    visibility: hidden;
    transition: all 1s ease-in-out;

    .holidays_list_item_action {
      position: relative;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60%;
      cursor: pointer;

      &:hover {
        background-color: rgba(109, 127, 146, 0.1);
      }

      &_divider {
        display: inline-block;
        height: 14px;
        width: 1px;
        background-color: rgba(109, 127, 146, 0.2);
      }
    }
  }
}

.holidays_list_item:hover {
  .holidays_list_item_actions {
    visibility: visible;
  }
}

.holidays_list_item_holiday {
  .holidays_list_item_type_circle {
    background-color: #ff636e;
  }
  &:hover {
    border-color: #ff636e;
  }
}

.holidays_list_item_annual_holiday {
  .holidays_list_item_type_circle {
    background-color: #f7953e;
  }
  &:hover {
    border-color: #f7953e;
  }
}

.holidays_list_item_absence {
  .holidays_list_item_type_circle {
    background-color: #1890ff;
  }
  &:hover {
    border-color: #1890ff;
  }
}

.holidays_list_item_other {
  .holidays_list_item_type_circle {
    background-color: rgb(121, 106, 217);
  }
  &:hover {
    border-color: rgb(121, 106, 217);
  }
}

.holidays_list_item_partial {
  .holidays_list_item_type_circle {
    background-color: rgb(209, 52, 51);
  }
  &:hover {
    border-color: rgb(209, 52, 51);
  }
}

.holidays_list_item_approve_actions {
  margin-left: auto;
  display: flex;
  gap: 6px;

  .holidays_list_item_approve_action {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f5;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: rgba(109, 127, 146, 0.2);
    }
  }
}

.reject_modal_ok_button {
  display: flex;
  align-items: center;
  gap: 4px;
}

.approve_popup_ok_button {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
}
