.content-notification {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;  
  .content-aside{
    .tab {
      .tab_label {
        color: #29394D;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      
      &.tab_active {
        border-color: #18a0fb;
        color: #18a0fb;
        background: #F3FAFF;
      }
    }
    .tab:hover{
      cursor: pointer;
    }
    
  }
  .content-frame {
    background: #FFF;
    width: 90%;
    
    .switch_tabs {
      font-size: 16px;
      color: #29394D;
      border: none;
      padding: 20px 0 0 50px;
      .tab {
        width: 90px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: #18A0FB;
        cursor: pointer;

        &_active {
          // background: #18A0FB;
          color: #F1F2F4;
        }
      }

    }
    .additional-line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      margin: 10px 0px;

      .tag {
        height: 26px;
        border-radius: 5px;
        min-width: 51px;
        padding: 5px 7px;
        margin: 0px 2px;
        background: #F1F2F4;
        color: #6D7F92;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        cursor: pointer;
        
        .p-checkbox {
          margin-left: 3px;
          width: 20px;
          height: 18px;
          .p-checkbox-box {
            width: 18px;
            height: 18px;
          }
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
        .p-checkbox .p-checkbox-box.p-highlight {
          border-color: #6d7f92;
          background: #6d7f92;
          color: #ffffff;
        }
      
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
          box-shadow: 0 0 0 0.2rem rgb(109, 127, 146, 0.2);
          border-color: #6d7f92;
        }
      
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
          border-color: #6d7f92;
        }
      }
      .min-tag{
        width: 26px;
        min-width: 26px;
      }
      .active {
        background: #f3faff;
        color: #18A0FB;
      }
      .disabled {
        opacity: 0.4;
        cursor:not-allowed;
      }
    }
    .notifications_list {

      width: 97%;
      padding: 0 20px 19px;
      border: none;
      overflow-y: auto;
      overflow-x: hidden;
      height: auto;
      margin: 20px;
      .list{
        width: 100%;
      }
      .notif_header_bu{
        border-bottom: 1px solid #E1E4E8;
      }
      .notif_line_bu {
        height: 40px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #6D7F92;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 1px solid var(--gris-3, #F1F2F4);
        background: #FFF;
        padding: 30px 30px;

        .tag {
          height: 26px;
          border-radius: 5px;
          border: 0.5px solid #F1F2F4;
          padding: 10px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          background: #FDF3EA;
          min-width: 77px;

          span {
            margin-left: 5px;
          }
          svg {
            color : #FFB06A;
          }
          .red {
            * {
              fill : #FF636E;
            }
          }
        }   
        .collaborator_name{
          margin-bottom: 10px;
          color: #29394D;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          .collaborator_bu {
            color: #6D7F92;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .avatars {
          margin: 0 10px 0 0;
          .avatar {
            position: relative;
            &:nth-child(2) {
              z-index: 2;
              right: 10px;
            }
            &:nth-child(3) {
              z-index: 3;
              right: 20px;
            }
            &:nth-child(4) {
              z-index: 4;
              right: 30px;
            }
            &:nth-child(5) {
              z-index: 5;
              right: 40px;
            }
            &_exist {
              width: 34px;
              height: 34px;
              margin: auto;
              border: 1px white solid;
              border-radius: 50%;
              overflow: hidden;
              cursor: default;
          
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          
            &_not_exist {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 34px;
              height: 34px;
              font-size: 12px;
              font-weight: 600;
              margin: auto;
              color: white;
              border: 1px white solid;
              border-radius: 50%;
              background-color: darkgray;
              cursor: default;
          
              & > span {
                margin: auto;
              }
            }
          }
        }
      }
      .notif_line_bu:hover {
        background-color: #F1F2F4;
      }

    }
  }
}
