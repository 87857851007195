$all_filter_color: rgb(60, 78, 100);
$rejected_filter_color: rgb(254, 55, 69);
$pending_filter_color: rgb(250, 173, 20);
$approved_filter_color: rgb(6, 217, 177);

.categories_filters {
  margin-top: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 28px;
  padding: 0 20px;

  .filter_item {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    position: relative;
    border: 1px solid transparent;
    border-radius: 15px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 300ms;
    padding: 0.4em 1.2em;
    margin: 0;
    position: relative;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;

    &_tag {
      font-size: 10px;
    }

    &_tag_count {
      position: absolute;
      top: -1em;
      right: 0.7em;
      border: 1px solid #f1f2f4;
      padding: 0 5px;
      border-radius: 5em;
      background-color: white;
      font-size: 8px;
      font-weight: bolder !important;
      min-width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .filter_item_all {
    border: 1px solid $all_filter_color;
    background-color: #fff;

    .filter_item_tag {
      color: $all_filter_color;
    }

    .filter_item_tag_count {
      color: $all_filter_color;
      border: 1px solid $all_filter_color;
    }

    &.filter_item_active {
      background-color: $all_filter_color;

      .filter_item_tag {
        color: #fff;
      }
    }
  }

  .filter_item_rejected {
    border: 1px solid $rejected_filter_color;
    background-color: #fff;

    .filter_item_tag {
      color: $rejected_filter_color;
    }

    .filter_item_tag_count {
      color: $rejected_filter_color;
      border: 1px solid $rejected_filter_color;
    }

    &.filter_item_active {
      background-color: $rejected_filter_color;

      .filter_item_tag {
        color: #fff;
      }
    }
  }

  .filter_item_pending {
    border: 1px solid $pending_filter_color;
    background-color: #fff;

    .filter_item_tag {
      color: $pending_filter_color;
    }

    .filter_item_tag_count {
      color: $pending_filter_color;
      border: 1px solid $pending_filter_color;
    }

    &.filter_item_active {
      background-color: $pending_filter_color;

      .filter_item_tag {
        color: #fff;
      }
    }
  }

  .filter_item_approved {
    border: 1px solid $approved_filter_color;
    background-color: #fff;

    .filter_item_tag {
      color: $approved_filter_color;
    }

    .filter_item_tag_count {
      color: $approved_filter_color;
      border: 1px solid $approved_filter_color;
    }

    &.filter_item_active {
      background-color: $approved_filter_color;

      .filter_item_tag {
        color: #fff;
      }
    }
  }
}
