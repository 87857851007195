.attachments_container {
  width: 100%;
  margin-bottom: 10px;

  .attachments_dropzone {
    &_inner {
      width: 100%;
      padding: 1rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      gap: 12px;
      background: #FAFBFB;
      border: 1px solid #F1F2F4;
      border-radius: 4px;
      height: 140px;
      background-color: white;

      .attachments_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }

      .attachments_item {
        display: flex;
        gap: 4px;
      }
    }

    .icon_upload {
      font-size: 2.5rem;
      padding-bottom: 1rem;
      color: #5081be;
      display: inline-flex;
    }

    .drag_message {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
    }

    p {
      margin-bottom: 0;
      font-size: 12.5px;
    }
  }
}
