.page-content {
  background-color: inherit;
  // position: absolute;
  // width: 100%;
  // margin-top: 3rem;
}

.content-notification {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 80%;
  margin: 0 auto;

  .filter-tabs {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .tab {
      display: flex;
      height: 40px;
      padding: 10px;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #F1F2F4;
      background-color: #FFFFFF;
      color: #29394D;
      font-size: 14px;
      font-weight: 500;
      box-shadow: 0px 0px 10px 0px rgba(41, 57, 77, 0.10);
      cursor: pointer;

      &:hover {
        box-shadow: none;
      }

      svg {
        // stroke: #29394D;
        width: 18px;
        height: 18px;
      }

      .count {
        display: flex;
        height: 20px;
        padding: 6px;
        margin-left: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #E1E4E8;
        color: #29394D;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 140%; /* 16.8px */
      }

      &-active {
        border: 1px solid #18A0FB;
        background: #F3FAFF;

        .count {
          background-color: #18A0FB;
          color: #FFFFFF;
        }
      }
    }
  }

  .content-frame {
    display: flex;
    flex-grow: 1;
    padding: 20px 19px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 600px;
    border-radius: 5px;
    border: 1px solid #F1F2F4;
    background-color: #FFFFFF;

    .content-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .selected-tab-title {
        color: #29394D;
        font-size: 16px;
        font-weight: 500;
      }

      .tabs-switcher {
        display: flex;
        gap: 6px;

        .tab {
          display: flex;
          padding: 9px 18px;
          gap: 10px;
          border-radius: 30px;
          background-color: #F1F2F4;
          color: #6D7F92;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          cursor: pointer;

          &-active {
            border-radius: 30px;
            background-color: #6D7F92;

            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: #E1E4E8;
      border: none;
      outline: none;
    }

    .notifications-list {
      width: 100%;
      border-radius: 5px;
      overflow-y: scroll;
      background: white;
    }
  }
}

.notification-item {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #F1F2F4;
  justify-content: space-between;

  .infos {
    gap: 10px;  
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &:hover {
    background-color: #F1F2F4;
    transition: color 300 linear;

    .notification-actions {
      visibility: visible;
    }
  }

  &-active {
    background-color: #F3FAFF;

    &:hover {
      background-color: #C7E8FE;
    }
  }

  .status-indicator {
    margin-bottom: 14px;
  }

  .avatar {
    align-self: flex-start;
  }

  .notification-content {
    flex-grow: 1;
    margin-top: 7px;

    &-title {
      color: #29394D;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      gap: 6px;

      .notification-type-icon {
        align-self: flex-start;
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background-color: #F1F2F4;

        &-active {
          background-color: #C7E8FE;
        }
      }
    }

    &-body {
      margin-top: 3px;
      font-size: 12px;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
      color: #6D7F92;

      .highlighted {
        color: #29394D;
        font-weight: 500;
      }
    }
  }

  .notification-date {
    color: #6D7F92;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
    align-self: center;
  }

  .notification-actions {
    display: flex;
    align-items: center;
    visibility: hidden;
    position: relative;

    &-visible {
      visibility: visible;
    }
  }

  .notification-actions-menu {
    display: flex;
    width: 200px;
    padding: 10px 0px;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #E1E4E8;
    background-color: #FFFFFF;
    position: absolute;
    right: 0;
    top: 22px;
    box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.10);
    z-index: 900;

    &-item {
      display: flex;
      padding: 12px 10px;
      align-self: stretch;
      border-bottom: 1px solid #F1F2F4;
      overflow: hidden;
      color: #29394D;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 400;

      &-danger {
        color: #DE4848;
      }
    }
  }

  .tag {
    display: flex;
    padding: 5px;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    text-align: right;
    background: #FFF;
    font-size: 12px;
    font-weight: 500;
    min-width: 77px;

    &-danger {
      background-color: rgba(235, 87, 87, 0.10);
      color: #DE4848;
    }
    &-warning {
      background-color: rgba(253, 239, 239, 0.1);
      color: #D87318;
    }
    &-success {
      background-color: rgba(231, 252, 248);
      color: #02af8e;
    }
    &-default {
      background: transparent!important;
      color: #29394D;
      min-width: 120px;
      justify-content: space-between;
    }
    &-min {
      min-width: fit-content!important;
    }
    &-hidden {
      opacity: 0;
    }
    &-button {
      cursor: pointer;
      min-width: fit-content;
    }
  }
}


.fetch-more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid #F1F2F4;

  &:hover {
    background-color: #F1F2F4;
  }
}

.no_data_notif {
  min-width: 200px!important;
  margin: 130px auto;
  box-shadow: none;
  .title {
    font-size: 17px;
    font-weight: 400;
  }
}
