.card {
  display: flex;
  // width: 250px;
  width: 100%;
  padding: 10px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #e1e4e8;
  background: #fff;

  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;

    & > span {
      color: #29394d;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    & > div {
      display: flex;
      gap: 10px;
      align-items: center;

      .badge {
        display: flex;
        height: 24px;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .badge_in {
        color: #02af8e;
        background: #edfaf5;
        text-transform: uppercase;
      }

      .badge_out {
        color: #de4848;
        background: #fdefef;
        text-transform: uppercase;
      }

      .badge_op,
      .badge_forfait {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .toggle_btn {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: #f1f2f4;
        transform: rotate(var(--angle, 0));
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  &_content {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;

    .column {
      display: flex;
      flex-direction: column;
      gap: 7px;
      flex: 1 0 0;

      div {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #29394d;
      }

      span {
        display: flex;
        color: #6d7f92;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_card {
        display: flex;
        padding: 10px 6px;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #e1e4e8;

        span {
          color: #29394d;
          text-align: left;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: auto !important;;
        }

        span.column_card_prestation {
          position: relative;
          margin-left: 10px;
          font-size: 10px;
          width: auto;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -10px;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background: #de4848;
            border-radius: 50%;
          }
        }
      }
    }

    .row {
      display: flex;
      padding: 10px 6px;
      justify-content: center;
      align-items: center;
      gap: 7px;
      flex: 1 0 0;
      align-self: stretch;

      &_grp_client {
        display: flex;
        padding: 10px 6px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3px;
        flex: 1 0 0;
        align-self: stretch;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        span {
          color: #6d7f92;
        }

        strong {
          color: #29394d;
        }
      }

      &_card {
        display: flex;
        padding: 10px 6px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 3px;
        flex: 1 0 0;
        border-radius: 5px;
        border: 1px solid #e1e4e8;
        align-self: stretch;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #29394d;

        &_attribution {
          display: flex;
          height: 24px;
          padding: 7px;
          justify-content: center;
          align-items: center;
          gap: 3px;
          border-radius: 5px;
          background: #edfaf5;
          color: #02af8e;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
