.container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 1000;
    .btn {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #2ce1be;
        color: #fff;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        border-radius: 50%;
        font-size: 14px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: background-color .3s;
        &:hover {
            background-color: #003558;
        }
    }
}