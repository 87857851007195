.ripple_loading {
  text-align: center;
  margin: 5rem auto;
  display: flex;
  justify-content: center;
  height: 250px;
}

.no_data {
  text-align: center;
  margin: 4rem 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  font-family: Roboto;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}

.ttp-tab-container {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.content-body {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  margin: 0px auto;
  margin-bottom: 40px !important;
  padding: 24px;
  width: 88%;

  &_global {
    padding: 20px 87px 120px;
    margin: 0 auto;
    background: transparent;
    width: 100%;
    border: 0px;
  }
}

.large-content {
  width: 90%;
}

.sync-section {
  display: flex;
  justify-content: flex-end;
  padding: 0 32px 14px;
  border-bottom: 1px solid #f1f2f4;

  &-button {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: #6d7f92;
  }

  &-skeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    color: #6d7f92;
  }
}
