.categories_header {
  display: flex;
  position: relative;
  z-index: 10;

  &::after {
    content: "";
    position: absolute;
    bottom: -36px;
    right: 0;
    left: 0;
    width: 100%;
    height: 67px;
    background-color: #f1f2f4;
    border-radius: 170px/32px;
    z-index: -1;
  }

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f2f4;
    color: #fff;
    position: relative;

    .top_bar {
      background-color: rgb(255, 99, 110);
      width: 100%;
      height: 5px;
    }

    &:first-of-type {
      .top_bar {
        border-top-left-radius: 5px;
      }
    }

    &:last-of-type {
      .top_bar {
        border-top-right-radius: 5px;
      }
    }

    .item_count {
      width: 44px;
      height: 44px;
      border-radius: 0px 0px 50px 50px;
      margin: -5px auto 5px;
      background-color: rgb(255, 99, 110);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
    }

    .item_label {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #29394d;
    }
  }

  .holiday_item {
    &_top_bar {
      background-color: rgb(255, 99, 110);
    }
    &_count {
      background-color: rgb(255, 99, 110);
    }
  }

  .annual_holiday_item {
    &_top_bar {
      background-color: rgb(247, 149, 62);
    }
    &_count {
      background-color: rgb(247, 149, 62);
    }
  }

  .my_holidays_item {
    &_top_bar {
      background-color: rgb(70, 149, 219);
    }
    &_count {
      background-color: rgb(70, 149, 219);
    }
  }

  .other_holiday_item {
    &_top_bar {
      background-color: rgb(121, 106, 217);
    }
    &_count {
      background-color: rgb(121, 106, 217);
    }
  }
}
