.header_container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.collaborator_info {
  display: flex;
  align-items: center;
  gap: 10px;

  .name {
    color: #29394D;
    font-size: 18px;
    font-weight: 600;
  }
}

.box_item {
  height: 64px;
  border-radius: 5px;
  border: 1px solid #29394D;
  background: #FFF;
  display: flex;
  padding: 10px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-left: auto;

  &:last-of-type {
    margin-left: 0;
  }

  .title {
    color: #29394D;
    font-size: 14px;
    font-weight: 600;
  }

  .value {
    color: #29394D;
    font-size: 24px;
    font-weight: 700;
  }

  .details {
    display: flex;
    padding: 3px 6px 3px 10px;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
    background: #F1F2F4;
    cursor: pointer;

    color: #29394D;
    font-size: 12px;
    font-weight: 500;
  }
}
