.card_container {
  display: flex;
  // min-height: 600px;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid #E1E4E8;
  background: #FFF;
}
