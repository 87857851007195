
.tab-container {
    margin-top: 2rem;
    margin-left: 70px;
    margin-right: 70px;
    // display: flex;

    .p-carousel {
        width: 100%;
        flex-direction: row;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
        background-color: #18a0fb;
    }

    .p-carousel[pr_id_139] .p-carousel-item {
        flex: none;
        width: 100%;
    }
}

.carousel-item{
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 96%;
    height: 52px;
    font-family: Roboto, "Segoe UI", Helvetica, sans-serif;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #18a0fb;
}
.active {
    color: #ffffff;
    background: #18a0fb;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    width: 52px;
    background: transparent!important;
    border-radius: 0px!important;
    &:focus {
        box-shadow: 0 0 0 0 !important;
    }

}

