$red-color: #d50000;
$green-color: #00a000;
$orange-color: #d8a200;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content {
  // padding: 12px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h4 {
    text-align: center;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    &_select {
      background-color: red;
    }
  }
}

.tabs {
  align-self: flex-start;
  width: 100%;

  font-size: 10px;
}

.client_list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.client_card {
  min-width: 100% !important;
  flex: 1 0;
  display: flex;
  padding: 10px 6px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--gris-2, #e1e4e8);
  color: #29394d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;

  .client_gap {
    &_positif {
      color: $green-color;
    }

    &_negatif {
      color: $red-color;
    }
  }
}

.charts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
