.filters {
  display: flex;
  gap: 8px;
}

.collaboratorsList {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  .title {
    margin: 8px 0;
    display: flex;
    gap: 6px;
  }
}

.collaboratorItem {
  display: flex;
  align-items: center;
  gap: 6px;

  .collaboratorEmail {
    font-size: 12px;
    color: #6d7f92;
  }
}
