$red-color: #d50000;
$green-color: #00a000;
$orange-color: #d8a200;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content {
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  position: relative;

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    &_select {
      background-color: red;
    }
  }
}

.data_view_type {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
}

.errors_table {
  width: 100%;
  max-width: 100%;
  font-size: 10px;
  font-weight: 500;

  &_cell {
    border: 1px solid #f1f1f2 !important;
  }

  &_paginator {
    font-size: 10px !important;
  }
}

.legend_container {
  border-bottom: 1px solid #F1F2F4;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 8px;
  width: 100%;
}

.grave_error_cell {
  .status_circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #DE4848;
    margin-right: 10px;
  }
}
