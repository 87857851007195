.months_column {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.month_item {
  height: fit-content;
  background: #f8f9fa;
  border: 1px solid rgba(109, 127, 146, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  position: relative;
  transition: all 0.3s linear;

  &_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }

  &_label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #000000;
  }

  &_action_buttons {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
    margin-right: 10px;
  }

  &_action_button {
    display: flex;
    transition: all .3s ease-in;
    padding: 4px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &:not(:last-of-type)::after {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: -7px;
      width: 0;
      margin: 0;
      border-left: 1px solid rgba(109, 127, 146, 0.2);
    }

    &:hover {
      background-color: rgba(109, 127, 146, 0.1);
    }
  }

  &_chevron_down {
    padding: 10px;
    border-radius: 50%;
    background-color: transparent;
    transition: background 0.3s linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -10px;
    visibility: hidden;

    &:hover {
      background-color: rgba(109, 127, 146, 0.1);
    }

    svg {
      cursor: pointer;
    }
  }

  &:hover {
    border: 1px solid #1890ff;
    .month_item_chevron_down {
      visibility: visible;
    }
  }

  &_body {
    width: 100%;
    // padding: 0.5rem 1rem;
  }

  &_footer {
    width: 100%;
    min-height: 50px;
    background-color: #f1f2f4;
    transition: all 0.3s ease;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_bloc {
      display: flex;
      justify-content: center;
      gap: 1.5rem;
    }

    &_item {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #29394d;
      cursor: pointer;

      &_option {
        box-sizing: border-box;
        border-radius: 30px;
        width: 18px;
        height: 18px;
        display: -ms-flexbox;
        display: flex;
        margin-right: 8px;
        border: 2px solid #ffffff;
        background-color: #ffffff;
      }

      &_absence {
        .month_item_footer_item_option {
          border-color: rgb(70, 149, 219);
        }

        &_active {
          font-weight: 500;

          .month_item_footer_item_option {
            background-color: rgb(70, 149, 219);
            border-color: #ffffff;
          }
        }
      }

      &_other {
        .month_item_footer_item_option {
          border-color: rgb(121, 106, 217);
        }

        &_active {
          font-weight: 500;

          .month_item_footer_item_option {
            background-color: rgb(121, 106, 217);
            border-color: #ffffff;
          }
        }
      }

      &_partial {
        .month_item_footer_item_option {
          border-color: rgb(209, 52, 51);
        }

        &_active {
          font-weight: 500;

          .month_item_footer_item_option {
            background-color: rgb(209, 52, 51);
            border-color: #ffffff;
          }
        }
      }
    }

    &_input {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      padding: 0.5rem;
      color: #6d7f92;
    }

    &_select {
      width: 100%;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
    }
  }
}
