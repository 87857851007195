.page_container {
  padding: 0 60px 20px 60px;
  margin: auto;
}

.tab_container {
  margin: 30px 0;
  display: flex;

  .p-carousel {
    width: 100% !important;
    margin: auto !important;
  }
}

.sync_icon {
  cursor: pointer;

  &_not_allowed {
    cursor: not-allowed;
  }
}

.syncing_icon {
  cursor: not-allowed;
}
