.submenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;


    .submenu_details {
        z-index: 1;
        margin-top: 12px;
        background-color: #384658;
        color: #fff !important;

        li {
          display: flex;
          align-items: center;
          color: #c7e8fe;
          font-family: Segoe UI, Helvetica, sans-serif;
          font-weight: 400;
          height: 44px;
          padding: 0.9rem 0.9rem;
          border-bottom: 1px solid rgb(52, 66, 84);

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background-color: #384658;
            color: #ffffff;
          }

          .submenu__link {
            color: inherit;
            display: flex;
            align-items: center;
            text-decoration: none;
            width: 100%;

            &.active {
              font-weight: bold;
            }
          }
        }
      }
    .menu__item_header {
      display: flex;
      padding: 12px 16px;
      gap: 7px;
      cursor: pointer;
      height: 44px;

      // img {
      //   margin-right: 0px;
      // }

      // span {
      //   padding-left: 0.5rem;
      //   text-transform: uppercase;
      //   margin: 0 8px 0 0;
      // }
  
    } 
  &:hover {
  background-color: #384658;
  // min-height: 44px; 
  }
  }