@use "../../../../styles/base/variables";

.matrix {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}

.header {
  font-weight: bold;
  padding: 10px 20px;
  border-right: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: var(--dark-1, #29394d);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  span {
    color: var(--dark-3, #6d7f92);
    text-transform: capitalize;
    font-size: 12px;
  }
}

.cell {
  padding: 10px 20px;
  border-bottom: 1px solid #e1e4e8;
  border-right: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    strong {
      color: #29394d;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    em {
      color: var(--dark-3, #6d7f92);
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .redirect_link {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: variables.$athens-gray;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #d8dde2;
    }
  }

  .mark {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
  }
}

.skeletonCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 10px 20px;
  border-bottom: 1px solid #e1e4e8;
  border-right: 1px solid #e1e4e8;
}

.skeletonAvatar {
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 50%;
}

.skeletonTextShort {
  width: 30px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 2px;
}

.skeletonTextMedium {
  width: 30px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeletonTextLong {
  width: 140px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeletonIcon {
  width: 20px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-right: 10px;
}

@keyframes skeleton-shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.skeletonCell * {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 50%, #f2f2f2 75%);
  background-size: 200% 100%;
  animation: skeleton-shimmer 1.2s infinite;
  border-radius: 5px;
}

