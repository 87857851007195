@use "../../../../styles/base/variables";

.card {
  display: flex;
  height: 244px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid variables.$cadet-blue;
  background: variables.$white;
  position: relative;
  animation: fadeInUp 0.3s ease forwards;

  &_header {
    display: flex;
    height: 32px;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    align-self: stretch;

    h2 {
      flex: 1 0 0;
      color: variables.$level-0-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .redirect_link {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: variables.$athens-gray;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #D8DDE2;
      }
    }
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
