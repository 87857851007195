.clients-in-container {
  position: relative;
  margin-bottom: 100px;

  .action-buttons {
    position: fixed;
    bottom: 40px;
    right: 100px;
    display: flex;
    gap: 8px;
    z-index: 1000;

    .p-button {
      width: 120px;

      &-label {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:disabled {
        cursor: not-allowed !important;
        pointer-events: all !important;
      }
    }
  }

  .datatable-header {
    border: none;
    padding-right: 0;
    padding-left: 0;
    background-color: #fafbfb;

    .datatable-header-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // &:first-of-type {
      //   margin-bottom: 6px;
      //   padding-bottom: 6px;
      //   border-bottom: 1px solid #f1f2f4;
      // }

      &:last-of-type {
        margin-bottom: 12px;
      }
    }

    .filters {
      width: 100%;
    }

    .data-count {
      margin-top: 8px;
      margin: 5px 2px 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #29394D;
      span {
        font-weight: 500;
      }
    }

    .display-info-buttons {
      display: flex;
      justify-content: flex-end;

      .display-info-button {
        background-color: white;
        border: 1px solid #3c4e64;
        padding: 6px 20px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        color: #3c4e64;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        border-radius: 0;
      }

      .display-info-button:hover {
        background-color: #f1f1f1;
      }

      .display-info-button.active {
        background-color: #3c4e64;
        color: #fff;
        border: 1px solid #3c4e64;
      }

      .display-info-button:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none;
      }

      .display-info-button:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: none;
      }
    }
  }

  .p-datatable-loading-overlay.p-component-overlay {
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 12px;
  }

  .p-paginator {
    button {
      width: fit-content;
    }
  }
}

.text-modal-dialog {
  width: 687px;

  .textarea {
    width: 100%;
  }
}

.clients-in-datatable {
  .p-datatable-frozen-tbody {
    tr {
      background-color: #f4f2f2;

      td {
        font-size: 13px !important;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
    .total-title {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .p-datatable-thead .datatable-column {
    height: 48px;
    padding: 6px;
    font-size: 11px;
    line-height: 14px;
    background-color: #3c4e64;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0px 1px;
    margin-left: 10px;

    &:hover {
      .p-highlight:hover  {
        background-color: #3c4e64;
        color: white;
      }
    }

    &:first-of-type {
      border-top-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
    }
  }

  .p-sortable-column, .p-sortable-column.p-highlight {
    background-color: #3c4e64;
    color: #fff;
    border: none;

    .p-sortable-column-icon {
      color: white;
    }

    &:not(.p-highlight):not(.p-sortable-disabled):hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;
    }

    &:not(.p-sortable-disabled):hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;

      .p-sortable-column-icon {
        color: white;
      }
    }

    &:hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;

      .p-sortable-column-icon {
        color: white;
      }
    }
  }

  .datatable-column {
    font-size: 14px;
    gap: 10px;

    &:first-of-type {
      border-top-left-radius: 12px;
    }

    &:last-of-type {
      border-top-right-radius: 12px;
    }
  }

  .datatable-cell-input {
    padding: 4px 6px;
    font-size: 12px;
  }

  .p-datatable-tbody tr {
    .client-name-cell {
      display: flex;
      justify-content: space-between;

      &-data {
        display: flex;
        flex-direction: column;

        .client-name-text {
          text-overflow: ellipsis;
          margin-bottom: 4px;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          display: flex;
          align-items: center;
          color: #29394D;
          text-transform: uppercase;
        }

        .client-name-info {
          display: flex;
          align-items: center;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          display: flex;
          align-items: center;
          color: #6D7F92;
        }
      }
      .client-name-icons {
        visibility: hidden;
        width: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 12px;
        transition: visibility .3 linear;

        .icon {
          cursor: pointer;
        }
      }
    }

    .centered-cell {
      display: block;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .client-name-icons {
        visibility: visible;
      }
    }
  }

  .cell-datepicker-input {
    background-color: #f1f2f4;
    color: #707f92;
    border: none;
    border-radius: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .cell-switch-input {
    width: fit-content;
    &-checked {
      background-color: #3c4e64;
    }
  }

  .cell-manager {
    // max-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      text-wrap: wrap;
      text-align: left;
    }
  }

  .input {
    background-color: #f1f2f4;
    color: #707f92;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
  }

  .cell-nine-months {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .cell-input-field {
      width: 100%;
    }

    &-volumetry {
      display: flex;
      gap: 6px;
      width: 100%;

      &-item {
        width: 33.333%;

        &:first-of-type {
          text-align: left;
        }

        &:last-of-type {
          text-align: right;
        }
      }

      span {
        display: inline-block;
        font-weight: bold;
        margin-right: 2px;
      }
    }
  }
}

.rdv-input-field {
  position: relative;

  .input {
    text-align: center;
  }

  .edit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -3px;
    top: 2px;
    transform: translateY(-50%);
    background-color: #f1f2f4;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
  }
}

.cell-volumetry {
  .volumetry-grid {
    background: #fff;
    // border-radius: 6px;
    // padding: 4px;
    font-size: 10px;

    .volumetry-header {
      display: grid;
      grid-template-columns: 60px 60px 60px;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 2px;
      align-items: center;
      justify-content: center;
      position: relative;

      .volumetry-value {
        font-weight: 800;
        color: #666;
        text-align: center;
      }
    }

    .volumetry-row {
      display: grid;
      grid-template-columns: 60px 60px 60px;
      gap: 8px;
      align-items: center;
      padding: 2px 0;

      .volumetry-label {
        color: #666;
      }

      .volumetry-value {
        text-align: center;
        background: #f1f2f4;
        border-radius: 4px;

        &:last-child {
          background: rgba(6, 217, 177, 0.1);
          color: #02af8e;
        }

        .volumetry-input {
          background-color: #f1f2f4;
          color: #495057;
          border: none;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          text-align: center;
        }
      }
    }
  }
}

.status-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.status-cell {
  .status_tag {
    margin: 0;
    margin-bottom: 8px;
  }

  .tag {
    margin: 0 auto;
    padding: 4px 7px;
    width: fit-content;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    align-items: center;

    svg {
      margin: 0px 5px;
      width: 16px;
    }

    &_success {
      background: rgba(6, 217, 177, 0.1);
      color: #02AF8E;
      margin-bottom: 8px;
    }
    &_danger {
      background: rgba(235, 87, 87, 0.1);
      color: #EB5757;
    }

    &_warning {
      background: #fefce8;
      color: #bb9762;
    }
  }

  .pie_frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    color: #EB5757;
    top: -30px;
    position: relative;
    height: 74px;

    @property --p{
      syntax: '<number>';
      inherits: true;
      initial-value: 0;
    }

    .pie {
      --p:20;
      --b:2.5px;
      --c:#EB5757;
      --w:150px;

      width:40px;
      aspect-ratio:1;
      position:relative;
      display:inline-grid;
      margin:5px;
      place-content:center;
      font-family: 'Roboto';
      font-weight:700;
      font-style: normal;
      font-size: 12px;
    }
    .pie:before,
    .pie:after {
      content:"";
      position:absolute;
      border-radius:50%;
    }
    .pie:before {
      inset:0;
      background:
        radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
        conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
              mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    }
    .pie:after {
      inset:calc(50% - var(--b)/2);
      background:var(--c);
      transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(760% - (var(--w) /2) / 2));
    }
    .animate {
      animation:p 1s .5s both;
    }
    .no-round:before {
      background-size:0 0,auto;
    }
    .no-round:after {
      content:none;
    }
    @keyframes p {
      from{--p:0}
    }
  }
}

.edit-texts-icons {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: center;

  .display-info-button {
    width: 28px;
    height: 28px;
    background-color: #fff;
    border: 1px solid #3c4e64;
    border-radius: 100%;
    padding: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    color: #3c4e64;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
