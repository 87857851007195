.container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.view_icon {
  cursor: pointer;
  padding: 9px 17px;
  background-color: #F1F2F4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;

  &_active {
    background-color: #6D7F92;
  }
}
