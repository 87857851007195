.bonus_custom_tabs {
  width: 100%;

  .ant-tabs-nav-list {
    // background-color: yellow;
    // width: 100%;
  }

  .ant-tabs-tab {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
}
