.line_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.line_space_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text_tranform {
  text-transform: capitalize;
}
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.calendar_informations {
  align-items: center;
  display: flex;
  font-size: 12px;
  height: 29px;
  justify-content: center;
  line-height: 14px;
  justify-content: space-between;
  width: 35%;
  margin: auto;
}
.informations {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #6d7f92;
}
.custom-calendar {
  font-family: Arial, sans-serif;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   border-bottom: 1px solid rgba(109, 127, 146, 0.3) !important;
  border-bottom: 0.5px solid var(--dark-1, #29394d);
  margin-bottom: 20px;
}

.current-day {
  background-color: #f0f0f0;
}

.planner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.planner-calendar {
  min-height: 600px;
  margin-bottom: 150px;
  .week_row {
    display: flex;
  }

  .avatar_exist,
  .avatar_not_exist {
    width: 26px !important;
    height: 26px !important;
  }
  .calendar_columns {
    margin: 0px 9px;
    width: 24%;
    height: auto;
    border-radius: 5px;
    padding: 3px;
    .day_label {
      display: flex;
      justify-content: center;
      text-transform: initial;

      text-transform: capitalize;
      color: var(--dark-1, #29394d);
      text-align: right;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      margin: 10px;
    }
    .day_content {
      border-top: 1px solid rgba(109, 127, 146, 0.3) !important;
      cursor: pointer;
      // &:hover {
      //   background: #f1f2f4;
      // }
    }
    .selected_day {
      // // background-color: rgba(24, 160, 251, 0.05);
      // color: #3b8ad5;
      // background: rgba(24, 160, 251, 0.1);
      // border-top: 1px solid #3b8ad5 !important;
    }
    .events {
      min-height: 200px;
      .backup_item {
        background-color: rgb(234, 129, 36, 0.05) !important;
        border: #d87318 !important;
      }
      .balance_event {
        background: #fefcfa !important;
      }
      .event {
        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
        position: relative;

        align-self: stretch;
        border-radius: 5px;
        border: 1px solid var(--gris-2, #e1e4e8);
        background: var(--blanc, #fff);
        margin: 10px 0 10px 0;
        .client_info {
          display: flex;
          padding: 5px 0px 10px 0px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          color: var(--Dark-1, #29394d);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          .client_name {
            display: flex;
            flex-direction: column;
            gap: 6px;
          }
        }
        .event_not_synched_icon {
          position: absolute;
          right: 10px;
          top: 38px;

          &_not_allowed {
            cursor: not-allowed;
            opacity: .3;
          }
        }
        .event_type {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          .circle {
            width: 9px;
            height: 9px;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            // background: #02af8e;
            margin: 0 8px 0 0;
            .tag-success {
              // background: rgba(6, 217, 177, 0.1);
              background: #02af8e;
            }
            .tag-warning {
              background: #d87318;
            }
            .tag-danger {
              width: 9px;
              height: 9px;
              position: relative;
              border-radius: 50%;
              overflow: hidden;
              background: #02af8e;
              margin: 0 8px 0 0;
              // background: rgba(235, 87, 87, 0.1);
              background: #eb5757;
            }
          }
          .type {
            color: var(--dark-3, #6d7f92);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: capitalize;
            margin-right: 10px;
          }
          .item_duration {
            margin-right: -25px;
          }
        }

        .category_name {
          color: var(--Dark-1, #29394d);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          text-transform: capitalize;
          color: var(--Dark-3, #6d7f92);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-transform: capitalize;
        }
        .event_actions {
          opacity: 0;
        }

        &:hover {
          background: #f1f2f4;
          z-index: 1;
          .duration {
            display: none;
          }
          .event_actions {
            opacity: 1;
            // transition: all 0.2s linear;
            // transform: translateX(-50%);

            & > div {
              margin-left: 10px;
              // margin-right: px;
              overflow: visible;
              // box-shadow: 0 0 10px rgba(41, 57, 77, 0.04);
              // border: 0.5px solid #c7e8fe;
            }
          }

          .list__row_groups_cell_wrapper {
            .list__row_group_cell_tag {
              background: #f8f9fa;
            }
          }

          & > div::after {
            border-left-color: #fafbfb;
          }
        }
      }
    }
  }
}

.filters_content {
  border-bottom: 1px solid rgba(109, 127, 146, 0.3) !important;
  margin-bottom: 20px;
  .filters {
    display: flex;
    margin-bottom: 6px;

    button {
      color: var(--dark-3, #6d7f92);
      width: 153px;
      height: 32px;
      padding: 6px 10px;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      border-radius: 5px;
      border: 1px solid var(--gris-2, #e1e4e8);
      background: var(--blanc, #fff);
      cursor: pointer;
    }
    .calender_type {
      border-right: 1px solid #e1e4e8;
    }
    .filter_custom {
      display: flex;
      justify-content: space-between;
      width: 251px;

      .mini-tabs-frame {
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #6d7f92;
        text-transform: uppercase;
        border-radius: 30px;
        background: #f1f2f4;

        > div {
          width: 120px;
          height: 100%;
          background: #f1f2f4;
          border-radius: 30px;
          padding: 8px 10px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background: #e1e4e8;
          }
          .span {
            width: 22px;
            height: 16px;
            color: #6d7f92;
            background: #fff;
            border-radius: 50px;
            font-size: 12px;
            font-weight: 500;
            padding: 1px 4px;
          }
        }

        &_active {
          color: #f1f2f4;
          background: #6d7f92 !important;
        }
      }
    }
  }
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  text-transform: capitalize;
  .actions{
    cursor: pointer;
  }
  .export_action {
    display: flex;
    margin-right: auto;
    margin-left: 30px;

    button {
      color: var(--dark-3, #6d7f92);
      width: 153px;
      height: 32px;
      padding: 6px 10px;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      border-radius: 5px;
      border: 1px solid var(--gris-2, #e1e4e8);
      background: var(--blanc, #fff);
    }
  }
}

.day_header {
  margin: 10px 0 11px 0;
  .realised {
    color: var(--dark-3, #6d7f92);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  .disponibility {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;

    border-radius: 5px;
    background: var(--Gris-4, #f1f2f4);

    color: var(--dark-1, #29394d);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}
.day_footer {
  background: linear-gradient(0deg, #f1f2f4 50%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.9;
  transform: translateY(-14px);
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--dark-1, #29394d);
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // display: flex;
  // justify-content: space-between;
  margin-top: 4px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // padding: 10px;

  svg {
    margin-right: 10px;
  }
  .action {
    > svg {
      transform: rotate(180deg);
      transition: transform 0.3s ease-out;
    }
  }
}

.collapsed {
  height: 240px !important;
  overflow: hidden;
}
.sideBar {
  overflow-y: scroll;
  width: 540px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 106;
  transform: none;
  transition: transform 100s;

  font-family: Roboto;
  font-style: normal;

  .overlay {
    background-color: rgba($color: #f8f9fa, $alpha: 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-checkbox {
    padding-right: 0px;
    padding-left: 0px;
  }
  .header {
    background: var(--Gris-5, #f8f9fa);
    display: flex;
    min-height: 40px;
    padding: 25px 30px;
    .header_title {
      color: var(--Dark-1, #29394d);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .occurrence {
      margin: 22px 0px 0 10px;
      display: flex;
      align-items: center;
      .title {
        margin-left: 20px;
      }
      span {
        color: var(--dark-1, #29394d);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-right: 15px;
        margin-left: 10px;
      }
    }
    .radio-container {
      margin: 22px 0px 0 10px;
    }
    .number_of_week {
      width: 50px;
      height: 30px;
      padding: 4px 10px;
      align-items: center;
      border-radius: 5px;
      border: 1px solid #d8dde2;
      background: #fff;
      color: var(--Dark-3, #6d7f92);
      // margin-right: 15px;
    }
  }
  .title {
    color: #29394d;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .calendar_select {
    display: contents;
    .p-calendar {
      height: 32px;
    }
    .p-inputtext {
      border-width: 1px 0px 1px 1px;
      border-radius: 4px 0px 0px 4px;
      border-color: #b2bcc6;
      background: #f8f9fa;
      width: 158px;
      margin: 0px;

      &:focus {
        box-shadow: none;
      }
    }
    .p-button {
      background: #f8f9fa;
      color: #6d7f92;
      border-width: 1px 1px 1px 0px;
      border-color: #b2bcc6;
      border-radius: 0px 4px 4px 0px;

      &:focus {
        box-shadow: none;
      }
    }
    .p-button.p-button-icon-only {
      width: 2rem;
    }
  }
  .sidebar_content {
    padding: 13px 30px;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-bottom: 100px;
    min-height: 350px;
    .title {
      margin-top: 13px;
      margin-bottom: 10px;
    }
    .sub_title {
      display: flex;
      align-items: center;
      .avatar {
        width: 50px;
      }
      .name {
        color: var(--Dark-1, #29394d);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
      }
    }
    .color-picker-container {
      display: flex;
      justify-content: space-around;
      gap: 10px;
      margin-top: 10px;
    }

    .color-circle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      transition: transform 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .color-circle.selected {
      transform: scale(1.2);
    }

    .color-circle.selected::before {
      content: "";
      position: absolute;
      top: -4px;
      left: -4px;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      border: 2px solid #18a0fb;
      box-sizing: border-box;
    }

    .inner-circle {
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
    }

    .selected-info {
      margin-top: 15px;
      font-size: 16px;
    }

    .rectangular_select {
      &_body {
        margin-top: 7px;
        width: 100%;
      }
      .rectangular_select_header {
        width: 100%;
        height: 34px;
      }
      .calendar_select {
        .p-calendar {
          height: 34px;
          margin-top: 5px;
        }
        .p-inputtext {
          border-width: 1px 0px 1px 1px;
          border-radius: 4px 0px 0px 4px;
          border-color: #b2bcc6;
          background: #f8f9fa;
          width: 258px;
          margin: 0px;

          &:focus {
            box-shadow: none;
          }
        }
        .p-button {
          background: #f8f9fa;
          color: #6d7f92;
          border-width: 1px 1px 1px 0px;
          border-color: #b2bcc6;
          border-radius: 0px 4px 4px 0px;

          &:focus {
            box-shadow: none;
          }
        }
        .p-button.p-button-icon-only {
          width: 2rem;
        }
      }
      .p-inputtext {
        border-width: 1px 0px 1px 1px;
        border-color: #b2bcc6;
        background: #f8f9fa;
        color: #29394d;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;

        &:focus {
          box-shadow: none;
        }
      }
      .p-button {
        background: #f8f9fa;
        color: #6d7f92;
        border-width: 1px 1px 1px 0px;
        border-color: #b2bcc6;
        border-radius: 0px 4px 4px 0px;

        &:focus {
          box-shadow: none;
        }
      }
      .p-button.p-button-icon-only {
        width: 2rem;
      }
    }
    .input-group,
    textarea {
      width: 120px;
      height: 34px;
      padding: 4px 10px;
      align-items: center;
      border-radius: 5px;
      border: 1px solid var(--Gris-1, #b2bcc6);
      background: var(--Gris-5, #f8f9fa);
      margin-right: 15px;
      display: flex;
    }
    .label_input {
      color: var(--dark-3, #6d7f92);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .duration_input {
      width: 60px;
      height: 30px;
      padding: 4px 10px;
      align-items: center;
      border-radius: 5px;
      border: none;
      background: var(--Gris-5, #f8f9fa);
      // margin-right: 15px;
    }

    .datepicker_input {
      background: #f8f9fa;
      border: 1px solid #b2bcc6;
      border-radius: 5px;
      height: 34px;

      input {
        &::placeholder {
          color: #6d7f92;
          font-size: 12px;
        }
      }
    }

    .diviser {
      border-bottom: 0.5px solid var(--gris-1, #b2bcc6);
      margin: 20px 0 10px;
    }
    .description {
      width: 100%;
      height: 131px;
      padding: 10px;
      margin-bottom: 10px;
    }
  }

  .add_collaborators_section {
    display: flex;
    flex-direction: column;
    // margin-top: 12px;
  }

  .add_collaborator_button {
    cursor: pointer;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #d8dde2;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(41, 57, 77, 0.1);
    color: var(--Dark-3, #6d7f92);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: auto;
  }

  .collaborator_remove_icon {
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .sidebar_footer {
    padding: 13px 30px;
    border-top: 1px solid #f1f2f4;
    position: sticky;
    bottom: 0;
    width: 540px;
    height: 95px;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 5;
    button {
      cursor: pointer;
      bottom: 30px;
      position: fixed;
      height: 34px;
      width: 110px;
      border-radius: 5px;
      border: 1px solid #d8dde2;

      background: #fff;

      /* shadow fenetre */
      box-shadow: 0px 2px 10px 0px rgba(41, 57, 77, 0.1);
      color: var(--Dark-3, #6d7f92);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
    .add {
      background: var(--bleu-1-principale, #18a0fb);
      color: #fff;
      right: 23px;
    }
    .disabled {
      // background: #18a0fb !important;
      cursor: pointer;
      opacity: 0.4;
      color: #ffffff;
    }
  }
}
.radio-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.radio-button {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid red;
  margin-right: 10px;
  cursor: pointer;
}

.input-group.no-margin-right {
  margin-right: 0 !important;
}

// .radio-button:checked {
//   background-color: blue;
// }
.conge_free {
  color: var(--dark-1, #29394d);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  border-radius: 5px;
  background: #fdc0c5;

  width: 58px;
  justify-content: center;
  display: flex;
  animation: ce;
}
.conge_maladie {
  color: var(--dark-1, #29394d);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  border-radius: 5px;
  background: #d4c3ff;
  width: 93px;

  justify-content: center;
  display: flex;
  animation: ce;
}
.conge_payy {
  color: var(--dark-1, #29394d);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  border-radius: 5px;
  background: #ffd49b;
  width: 93px;

  justify-content: center;
  display: flex;
  animation: ce;
}

.place {
  color: var(--dark-1, #29394d);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 15px;
  margin-left: 10px;
}

.line_time_sheet {
  display: flex;
  height: 59px;
  width: 100%;
  padding: 7px 0;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gris-2, #e1e4e8);
  &:hover {
    background: var(--gris-3, #f1f2f4);
    // background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
    bottom: 0.5rem;
    z-index: 1;
  }
  .info {
    margin-left: 10px;
    width: 55%;
    color: var(--Dark-1, #29394d);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .child_info {
    width: 45%;
    margin-left: 10%;
  }
  .tag {
    width: 15%;
    .content {
      border-radius: 5px;
      background: var(--gris-2, #e1e4e8);
      display: flex;
      width: 72.149px;
      height: 28px;
      padding: 6px;
      align-items: flex-start;
      gap: 8px;
    }
    .tag-success {
      background: rgba(6, 217, 177, 0.1);
      color: #02af8e;
    }
    .tag-danger {
      background: rgba(235, 87, 87, 0.1);
      color: #eb5757;
    }
  }

  .action {
    width: 5%;
  }
}
.selected_line {
  background: var(--gris-3, #f1f2f4);
}
.selected_child {
  background: #f8f9fa;
}
.line {
  display: flex;
  height: 44px;
  padding: 7px 0px;
  .infos {
    min-width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #29394d;

    .avatar {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      border-radius: 50%;
      border: 2px solid #e1e4e8;
      background-size: cover;
      background-position: top center;
    }

    div {
      &:nth-child(2) {
        width: 55%;
      }
      &:nth-child(3) {
        min-width: 132px;
        text-align: start;
      }
    }
  }
  .tag {
    width: 15%;
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #29394d;
    }
    > div {
      height: 28px;
      width: 58px;
      background: #f1f2f4;
      border-radius: 5px;
      margin-top: 10px;

      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      padding-right: 5px;
      justify-content: flex-end;
      color: #6d7f92;
    }
  }

  .tag-danger {
    > div {
      background: rgba(235, 87, 87, 0.1);
      color: #de4848;
    }
  }

  .tag-warning {
    > div {
      background: rgba(234, 129, 36, 0.1);
      color: #ea8124;
    }
  }

  .tag-success {
    > div {
      background: rgba(6, 217, 177, 0.1);
      color: #02af8e;
    }
  }
}

.tab_list {
  color: var(--dark-1, #29394d);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  margin-left: 10px;
  margin-right: 10px;
}

.order {
  color: var(--dark-3, #6d7f92);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  cursor: pointer;
}

.title_tabs_ti {
  color: var(--Dark-1, #29394d);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.progression_header {
  font-size: 14px !important;
}

.client_email_container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.client_email_switch_container {
  display: flex;
  gap: 4px;
  align-items: center;
}

.client_email_notice {
  font-size: 11px;

  // &_ok {
  //   color: #02af8e;
  // }

  &_nok {
    color: #de4848;
  }

  &_link {
    color: #29394d;
    cursor: pointer;
  }
}

.additional_emails {
  &_section {
    display: flex;
    flex-direction: column;
  }

  ol {
    list-style-type: armenian;
  }

  &_list {
    list-style-type: decimal !important;
    margin-left: 20px;
  }

  &_item {
    display: flex;
    align-items: center;
    // gap: 8px;
    font-size: 12px;

    &_remove_icon {
      margin-left: 12px;
      cursor: pointer;
    }

    &::before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 6px;
      background: #6c757d;
    }
  }

  &_input {
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #b2bcc6;
    background-color:  #f8f9fa;
    color: rgb(141, 141, 141);

    input {
      background-color:  #f8f9fa;
      color: rgb(141, 141, 141);
      font-size: 14px;

      &::placeholder {
        color: #6c757d;
      }
    }

    &:hover, &:focus, &:focus-within {
      outline: none;
      box-shadow: none;
      border: 1px solid #b2bcc6;
    }

    &::placeholder {
      color: #6c757d
    }

    &_add_button_disabled,
    &_remove_button_disabled {
      opacity: 0.4;
    }
  }

  &_add_button {
    margin-top: 12px;
    margin-left: auto;
    font-size: 12px;
    cursor: pointer;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #d8dde2;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(41, 57, 77, 0.1);
    color: var(--Dark-3, #6d7f92);
    font-family: Roboto;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}

.meeting_link {
  .meeting_link_input_container {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 4px 8px;
    border: 1px solid #b2bcc6;
    background-color: #f8f9fa;

    .meeting_link_input {
      flex: 1;
      border: none;
      outline: none;
      padding: 4px;
      font-size: 12px;
      background-color: transparent;
      width: calc(100% - 40px);
      border-radius: 5px;

      &:read-only {
        cursor: default;
      }
    }

    .copy_button {
      background: none;
      border: none;
      padding: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
      }

      .copy_icon {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

.add {
  background: var(--bleu-1-principale, #18a0fb);
  color: #fff;
  right: 23px;
}
.button_ee {
  color: var(--dark-3, #6d7f92);
  width: 153px;
  height: 32px;
  padding: 6px 10px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 5px;
  border: 1px solid var(--gris-2, #e1e4e8);
  background: var(--blanc, #fff);
}

.fixed-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  .header {
    display: flex;
    height: 36px;
    width: 100%;
    padding: 0px;
    .tabs {
      display: flex;
      width: 90%;
      justify-content: flex-start;
      padding: 0px 0px 0px 75px;
      .tab {
        height: 36px;
        background: var(--Gris-4, #f1f2f4);
        width: 19%;
        margin-left: 12px;
        border: 1px solid var(--gris-2, #28394d);
        color: var(--dark-1, #28394d);
        display: flex;
        align-items: center;
        border-radius: 5px 5px 0px 0px;
        padding: 13px;
        justify-content: space-between;

        &.selected-tab {
          color: #f1f2f4;
          background-color: #28394d;
          font-weight: bold;
        }
        & span {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          letter-spacing: 0.02em;
          // color: var(--dark-1, #29394d);
          text-transform: uppercase;
        }
        .content-header {
          display: flex;
          justify-content: center;
          align-items: center;
          .circle-warning {
            width: 8px;
            height: 8px;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            background: #d87318;
            margin: 0 8px 0 0;
          }
        }
      }
    }
    .tabs_close {
      margin-right: 24px;
      margin-top: -30px;
      height: 36px;
      background: #28394d;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 13px;
      justify-content: space-between;
    }
  }
  .in-progress-tasks-body {
    height: 470px;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    padding: 23px 84px;
    resize: vertical; /* Permet le redimensionnement vertical */
    // overflow: hidden;

    .calendar_select {
      width: 135px;
      .ant-picker {
        background: none;
        border: none;
      }
      .custom_datepicker_wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #e1e4e8;
        border-radius: 30px;
        padding: 0 10px;
        width: 170px;
        height: 30px;
        background: #fff;
        transition: background 0.3s ease-in-out;
      }
      .custom_datepicker_wrapper.has-date {
        background: rgba(
          6,
          217,
          177,
          0.1
        ) !important; /* Change le fond si une date est sélectionnée */
      }

      .calendar_icon {
        font-size: 14px;
        color: #555;
        margin-right: 8px;
      }
    }

    // .custom_input {
    //   display: flex;
    //   align-items: center;
    //   border: 1px solid #e1e4e8;
    //   border-radius: 30px;
    //   padding: 0 10px;
    //   width: 160px;
    //   height: 30px;
    //   background: #fff;
    //   cursor: pointer;
    // }

    // .datepicker_icon {
    //   margin-right: 8px;
    //   font-size: 14px;
    //   color: #555;
    // }

    // .hidden_native_input {
    //   border: none;
    //   outline: none;
    //   background: none;
    //   width: 100%;
    //   font-size: 14px;
    // }
  }
  .item_progress_container {
    width: 120px;
    .item_progress_bar {
      height: 4px;
      background: #29394d;
      border-radius: 3px;
    }

    .progress_budget {
      color: var(--dark-3, #6d7f92);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .selected_tva_filtre {
    padding: 4px 4px 4px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--dark-3, #6d7f92);
    min-width: 118px;
    margin-right: 10px;
    height: 22px;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .content_titles {
    color: var(--dark-3, #6d7f92);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: capitalize;
  }
  .contant_values {
    margin-left: 10px;
    color: var(--Dark-1, #29394d);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .date_filter {
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 10px 10px;
    color: #6d7f92;
    font-family: Roboto;
    .group_icons {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
    }
  }
  .tva_filter_active {
    font-weight: 500;
    border-bottom: 2px solid #18a0fb !important;
    color: #18a0fb;
  }
  .filter_tva {
    // padding: 8px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
    // border-bottom: 2px solid #6d7f92;
    border-bottom: 1px solid rgba(109, 127, 146, 0.2);
    color: #6d7f92;
    background: none !important;
  }
  .in_progress_balances_data {
    display: flex;
    height: 70px;
    justify-content: space-between;
    padding: 10px;
    align-items: flex-start;
    border-radius: 5px;
    border: 1px solid var(--gris-2, #e1e4e8);
    margin: 10px 10px 10px 2px;
    position: relative;
    align-items: center;
    .client {
      width: 20%;
      display: flex;
    }
    .content {
      display: flex;
      align-items: center;
      color: var(--Dark-1, #29394d);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .step {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .circle-sucess {
        width: 8px;
        height: 8px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        background: #02af8e;
        margin: 0 8px 0 0;
      }
      .circle-warning {
        width: 8px;
        height: 8px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        background: red;
        margin: 0 8px 0 0;
      }
    }
    .billing_type {
      width: 25%;
      display: flex;
    }
    span {
      color: #6d7f92;

      font-size: 10px;
      font-weight: 500;
      padding: 1px 4px;
    }
    .in_progress_balances_actions {
      display: flex;
      align-items: center;
      // justify-content: center;
      color: #18a0fb;
    }

    .closing_date {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .in_progress_balances_actions {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      background: #f1f2f4;
      z-index: 1;

      .in_progress_balances_actions {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .options_menu {
    position: absolute;
    left: 22%;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    padding: 5px 0;
    min-width: 200px;
  }

  .option {
    padding: 10px 15px;
    cursor: pointer;
    transition: red 0.2s ease;

    &:hover {
      background: #f1f2f4;
    }
  }
  .resize-handle {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    cursor: ns-resize;
    background-color: #ccc;
  }
  .rectangular_select_header {
    display: flex;
    width: 180px;
    height: 32px;
    padding: 9px 15px;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px !important;
    border: none;
    background: #f1f2f4;
    margin-left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #6d7f92;
    text-transform: uppercase;
  }
  .rectangular_select_body {
    display: flex;
    margin-left: 10px;
    // align-items: center;
    // border-radius: 10px ;
    // border: none;
    // margin-left: 10px;
    // font-weight: 500;
    // font-size: 12px;
    // line-height: 14px;
    // text-align: center;
    // letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.datepicker_popup {
  font-size: 12px;
  .ant-picker-footer {
    padding: 0 12px;

    .ant-picker-now-btn,
    .ant-btn {
      font-size: 12px;
    }

    .ant-btn {
      background-color: #18a0fb;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 0 10px;
    }
  }
}

.filter-container {
  position: relative;
  display: inline-block;
  margin-left: 30px;
  margin-right: 10px;
}

.filter-button {
  width: 106px;
  height: 32px;
  display: flex;
  padding: 9px 17px;
  align-items: flex-start;
  gap: 20px;
  border-radius: 30px;
  // background: var(--Dark-3, #6d7f92);
  background: #f1f2f4;
  border: none;
  text-transform: uppercase;
  color: #6d7f92;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}

.filter-dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  padding: 10px;
  z-index: 10;
}
.balance-dropdown {
  position: absolute;
  transform: translateX(-50%);
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  padding: 10px;
  z-index: 10;
  .title {
    color: var(--dark-3, #6d7f92);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 3px 10px;
  }
  .rectangular_select_body_option {
    height: 30px;
    color: var(--Dark-1, #29394d);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
}

.filter-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  color: var(--Dark-1, #29394d);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.filter-item input {
  margin-right: 10px;
}

.outlook-associated-text {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  color: #02af8e;
}
.rectangular_select_body_option {
  height: 30px;
  color: var(--Dark-1, #29394d);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
