.page_content {
  color: #29394d;
  padding: 0.71rem 4.6%;
  position: relative;
  display: flex;
  width: 100%;
  margin: auto;
  .content {
    display: flex;
    width: 100%;
    .sub_titles {
      color: #fff;
      width: 18%;
      margin: 11px;

      font-size: 14px;

      line-height: 16px;
      padding: 2rem 0rem;
      position: relative;
      .step {
        display: flex;
        padding: 10px;
        cursor: pointer;
        .text {
          color: #b2bcc6;
          width: 94%;
          margin-right: 5px;
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(109, 127, 146, 0.3);
          .label {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            text-transform: uppercase;
            width: 96%;
          }
        }
        .cercle {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          background-color: white;
          border: 4px solid #f1f2f4;
          border-radius: 50%;
          float: right;
          -webkit-filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
          filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
          z-index: 5;
          cursor: pointer;
          &.selected {
            background-color: #18a0fb;
            border: 4px solid white;
          }
        }
        span {
          position: absolute;
          margin-top: 17px;
          border-left: 4px solid #d8dde2;
          height: 33px;
          display: inline-block;
          z-index: 1 !important;
        }
      }

      .settings_sub_title {
        margin-top: 100px;

        .text {
          border-top: 1px solid rgba(109, 127, 146, 0.3);
          padding-top: 16px;
        }

        .step {
          .cercle {
            display: none;
          }
        }
      }
    }
    .bloc {
      width: 78%;
      margin: 11px;
      margin-bottom: 130px;
      min-height: 559px;
      position: relative;
      background: #ffffff;
      border: 0.5px solid #f1f2f4;
      -webkit-box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      padding: 24px 28px;
      .title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        color: #29394d;
        margin: 10px 0px 18px 0px;
      }
      .group_info {
        &:hover {
          background: #f1f2f4;
          cursor: pointer;
        }
      }
      .selected_info {
        background: #f1f2f4;
      }
      .info {
        height: 60px;
        border-left: 1px solid rgba(109, 127, 146, 0.3);
        display: flex;
        // margin-bottom: 21px;
        padding-bottom: 8px;
        align-items: center;
        .info_content {
          margin: 23px;
          width: 100%;
          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #6d7f92;
          }
          .value {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;
            color: #29394d;
          }
          .voyant_goup {
            display: flex;
            height: 35px;
            align-items: center;
            .voyant {
              display: inline-flex;
              height: 28px;

              gap: 8.149px;
              border-radius: 5px;
              border: 1px solid var(--Gris-1, #b2bcc6);
              background: var(--Gris-5, #f8f9fa);
              width: 96px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 6px 5px 6px 5px;
            }
          }
        }
      }
      .group_title {
        display: flex;
        .reduce_table_action {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #6d7f92;
          border-color: #f1f2f4;
          height: 28px;
          background: #ffffff;
          border: 0.5px solid #f1f2f4;
          box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
          border-radius: 17px;
          height: 40px;
          border: 1px solid rgba(225, 228, 232, 1);
          width: 160px;
          margin-left: auto;
        }
        .reduce_action {
          color: rgba(41, 57, 77, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .volumetrie {
        background: #f8f9fa;
        border-radius: 5px;
        padding: 24px 23px;
        margin: auto;
        span {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-transform: capitalize;
          color: #29394d;
        }
        .list {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(109, 127, 146, 0.3);
          height: 40px;

          .volumetrie_item {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #29394d;
          }

          .value {
            height: 22px;
            align-items: center;
            display: flex;
            box-sizing: border-box;
            border-radius: 5px;
            background: #f1f2f4;
            margin-left: auto;
            width: 54px;
            margin: auto;

            .label {
              margin: auto;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              text-align: right;
              color: #29394d;
            }
          }
        }
      }
    }
  }
}

.header_table {
  height: 62% !important;
  margin-top: 16.5%;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-top: 1px solid #29394d;
}

.description {
  margin-top: 5px;
  text-transform: capitalize;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #6d7f92;
}
.title_value {

}

.all {
  justify-content: center;
  display: flex;
  width: 100%;
  .list_grid {
    padding: 7px;
  }
}

.bordered_column {
  border-left: 1px solid #29394d;
  border-right: 1px solid #29394d;
  width: 90%;
  margin-left: auto;
  height: 100%;
  align-items: center;
  display: flex;
}
.not_edited_bordered_column {
  border-left: 1px solid rgba(109, 127, 146, 0.3);
  border-right: 1px solid rgba(109, 127, 146, 0.3);
}
.not_edited_bordered_footer {
  border-bottom: 1px solid rgba(109, 127, 146, 0.3) !important;
}
.total_header {
  border-top: 1px solid rgba(109, 127, 146, 0.3);
}

.footer_bottom {
  border-bottom: 1px solid rgba(109, 127, 146, 0.3);
}

.year_title {
  width: 81% !important;
  justify-content: center;
  margin: -12px auto auto auto !important;
  border-radius: 5px;
  background: #f8f9fa !important;
  // width: 40px !important;
  // marginRight: "7px",
  //               margin: "-40px 0px 0px 9px",
}

.footer {
  height: 45%;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom: 1px solid #29394d;
  margin-top: -21px;
}

.total_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #29394d !important;
}
.line_buttom {
  border-bottom: 1px solid #29394d !important;
}

.columns {
  width: 97px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.items_table {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 42px;
  height: 61px;
  background: #f1f2f4;
  border-radius: 5px;
  margin: auto;
}
.input_box {
  margin: 0;
  width: 50px;
  height: 23px;
  margin: 0px 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3c4e64;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #b2bcc6;
  border-radius: 4px;
  text-align: right;
  margin-bottom: 3px;
}
.inputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #b2bcc6;
  cursor: no-drop;
  margin: 4px;
  width: 72.15px;
  border-radius: 4px;
  height: 28px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #29394d;
  // border: 1px solid #b2bcc6;
}
.input {
  background: none;
  border: none;
  width: 55px;
  text-align: right;
}
.disabled {
  background: #f1f2f4;
  border: none;
  cursor: no-drop;
}

.title_disabled {
  display: flex;
  margin: auto;
  margin-bottom: 3px;
}

.active_step {
  background-color: white !important;
  border: 4px solid #18a0fb !important;
}
.active_label {
  color: #18a0fb;
}

.footer_actions {
  width: 108%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  margin-left: 7%;
  .button_next {
    background: #18a0fb;
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    width: 172px;
    height: 44px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
  }
  .last_step {
    background-color: rgb(6, 217, 177) !important;
  }
  .button_prev {
    background: #18a0fb;
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    // margin-bottom: 40px;
    width: 172px;
    height: 44px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    background: #ffffff;
    color: #6d7f92;
    margin-right: 50%;
    cursor: pointer;
  }
}

.sucess_total {
  // background-color: red !important;
  background: rgba(6, 217, 177, 0.1) !important;
  // color: red;
  color: #06d9b1;
  border: none !important;
}

.insucess_total {
  // background-color: red !important;
  background: rgba(235, 87, 87, 0.1);
  // color: red;
  color: #de4848;
  border: none !important;
}

.button_offre {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6d7f92;
  border-color: #f1f2f4;
  height: 28px;
  background: #ffffff;
  border: 0.5px solid #f1f2f4;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  border-radius: 17px;
  width: 200px;
  & span {
    margin-left: 9px;
  }
}

.line {
  background: #f1f2f4;;
  border-radius: 5px;
  padding: 24px 23px;
  margin: auto;
  transition: display 0.5s ease-in;

  .contry {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #29394d;
  }
  .role_content {
    margin-top: 2%;
    margin-right: 17%;
    min-height: 136px;
    border-bottom: 1px solid rgba(109, 127, 146, 0.3);
    .garbadge {
      display: flex;
      align-items: center;
      margin: 2%;
    }
  }
  .role {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    text-transform: capitalize;

    color: #29394d;
  }
  .add_button {
    background: #ffffff;

    width: 34px;
    height: 34px;
    border: 1px solid #b2bcc6;
    cursor: pointer;
    border-radius: 4px;
  }
  .add_delegate_label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    margin-left: 3%;
    // text-transform: uppercase;

    /* Dark 3 */

    color: #6d7f92;
  }
  .delete_button {
    height: auto !important;
    margin: 0 4px 0 5px;
  }

  .settings {
    width: 40%;
    height: 100%;
    border-radius: 12px;
    .title_settings {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: #29394d;
      margin: 10px 0px 18px 0px;
    }
    .calendar_content {
      margin: 26px 0px 37px 0px;
      .date_title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 12px;

        color: #29394d;
        margin: 10px 0px 18px 0px;
      }
    }
  }
  .categories_list {
    margin-left: 23px;
    border-left: 1px solid rgba(109, 127, 146, 0.3);
    width: 73%;
    .categories {
      // width: 50%;
      margin: 0% 3%;
      .category_item {
        border-bottom: 1px solid rgba(109, 127, 146, 0.3);
        font-size: 12px;
        line-height: 14px;
        display: flex;
        height: 29px;
        justify-content: center;
        align-items: center;
        .switch {
          margin-left: auto;
        }
      }
    }
  }
}
.save_button {
  background: #06d9b1;
  margin: auto 30% 80px 30%;
  margin-bottom: 90px;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  margin-bottom: 40px;
  width: 172px;
  height: 44px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  // margin-left: auto;
  cursor: pointer;
}
.enabled_button {
  opacity: 0.4;
  cursor: not-allowed !important;
}
$shadow-like-border: 0 0 0 0.06rem rgba(0, 0, 0, 0.06),
  0 0.13rem 0.2rem 0 rgba(0, 0, 0, 0.06);
$main-blue: rgb(24, 160, 251);
$h-font-family: "Montserrat";

.ttp-switch {
  position: absolute;
  opacity: 0;

  & + div {
    vertical-align: middle;
    transition-duration: 0.4s;
    transition-property: background-color, box-shadow;
    cursor: pointer;
    width: 40px;
    height: 16px;
    background-color: #dbe0e5;
    border-radius: 20px;

    & > div {
      position: relative;
      top: -25%;
      left: 0;
      width: 24px;
      height: 24px;
      background: #b6bfc8;
      border: 1px solid #ffffff;
      border-radius: 50%;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

      transition-timing-function: cubic-bezier(1, 0, 0, 1);
      transition-duration: 0.4s;
      transition-property: transform, background-color;
      pointer-events: none;
    }
    span {
      position: absolute;
      font-size: 1.6rem;
      color: white;
      margin-top: 12px;
      &:nth-child(1) {
        margin-left: 15px;
      }
      :nth-child(2) {
        margin-left: 55px;
      }
    }
  }
  &:checked {
    & + div {
      background-color: #c7e8fe;

      & > div {
        background-color: #18a0fb;
        transform: translate3d(16px, 0, 0);
      }
    }
  }
}

.span {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.modal {
  position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999 !important;
    background-color: rgba(41, 57, 77, 0.4);
    overflow-y: scroll;

  // height: 100%;
  // width: 100%;
  // overflow: hidden;
  // display: flex;
  // flex-flow: row wrap;
  // position: relative;
  justify-content: center;
  display: flex;
  // margin-top: 7%;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(41, 57, 77, 0.6);
  z-index: 108;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.modalOverlayAfterOpen {
  opacity: 1;
}

.modalOverlayBeforeClose {
  opacity: 0;
}

.modalContent {
  position: absolute;
  top: 0;
  left: 0;
  right: -100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  overflow: none;
  outline: none;
  padding: 0;

  transition: top 300ms ease-in-out;
}

.modalContentAfterOpen {
  right: 0;
}

.modalContentBeforeClose {
  top: -100%;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.body {
  position: absolute;
  width: 45%;
  background-color: #ffffff;
  color: #29394d;
  padding: 36px 56px 0 56px;
  margin: 40px 0 50px 0;
  display: block;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-content: flex-start;
  min-height: 0px;
  min-width: 0px;
  background: #f8f9fa;
  border-radius: 5px;
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #29394d;
  }
  .table {
    margin-top: -12px;
  }
}
.center {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
}

.calendarStyle {
  font-family: Arial, sans-serif;
  border: "1px solid #ccc";
  border-radius: 4px;
  padding: 10px;
  width: 300px;
}
.dayStyle {
  color: "#333";
  background-color: #f5f5f5;
}

.actionBar {
  border-top: 1px solid #f1f2f4;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  z-index: 5;
  > div {
    // display: flex;
    justify-content: space-between;
  }

  input {
    margin-right: 10px;
  }
  label {
    margin-right: 45px;
  }
}
.percentage_label {
  font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #6d7f92;
    width: 29%;
}
