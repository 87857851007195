.calendar_input {
    font-size: 12px;
     width: 100%;

    .p-button.p-button-icon-only {
      background: white;
      border: 1px solid #ced4da;
      border-left: none;
      outline: none;
    }
  }
