.page-content {
  .ripple_loading {
    height: 180px;
  }
  // .container_echeances {
  //   padding: 15px 10px 120px;
  .echeance_container {
    padding: 15px 10px 120px;
    .filter_custom {
      width: 456px;
    }
    .content {
      .name {
        .role {
          color: var(--dark-3, #6d7f92);
          font-size: 12px;
        }
      }
      .collaboators_list {
        width: 22%;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        margin: 11px;
        .collaborator {
          display: flex;
          // height: 54px;
          padding: 10px;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          border-radius: 5px;
          border: 1px solid var(--gris-3, #f1f2f4);
          background: #fff;
          .avatar {
          }

          .percentage {
            display: flex;
            height: 31px;
            padding: 6px;
            flex-direction: column;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid var(--gris-2, #e1e4e8);
            margin-left: auto;
          }
        }
        .selected_collaborator {
          border: 1px solid var(--bleu-1-principale, #18a0fb);
          background: var(--bleu-ciel-1, #f3faff);
        }
      }
    }
  }
  // }
}
.name {
  color: var(--dark-1, #29394d);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.balances_page {
  width: 100%;
  .balances {
    width: 100%;
    padding: 24px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin: 11px;
    // width: 810px;
    min-height: 874px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gris-3, #f1f2f4);
    background: #fff;
    .balance_header {
      display: flex;
      align-self: stretch;
      margin-bottom: 10px;
      .name {
        margin-top: 9px;
      }
      .types {
        margin-left: auto;
        display: flex;
        .item_type {
          width: 135px;
          margin: 0px 3px;
          padding: 7px 0px;
          border-bottom: 3px solid;
          border-color: #b2bcc6;
          cursor: pointer;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          line-height: 16px;
          color: #6d7f92;
        }
        .selected_type {
          color: #18a0fb !important;
          border-color: #18a0fb !important;
        }
      }
    }
    .echeanches_filter {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid var(--gris-3, #e1e4e8) !important;
      width: 100%;
      .item {
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 24px;
        margin: 5px;
        padding: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #f1f2f4;
        font-size: 12px;
      }
      .active {
        color: #f1f2f4;
        background: #6d7f92 !important;
      }
    }

    .table {
      width: 100%;
      .remarks_content {
        display: flex;
        align-items: center;
        margin-left: 3%;
      }
      .bilan_table {
        display: flex;
        width: 100%;
        height: 60px;
        padding: 0 0px 0px 20px;
        justify-content: space-around;
        align-items: center;
        // background-color: #f8f9fa;

        .bilan_item {
          border-right: 1px solid var(--gris-3, #3c4e64) !important;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18%;
          height: 100%;

          .first_content {
            background-color: #fff !important;
          }
          .content {
            width: 100%;
            height: 90%;
            justify-content: center;
            align-items: center;
            background-color: #f8f9fa;
            .checked_item {
              width: 100%;
              height: 20px;
              padding: 4px;
              display: flex;
              justify-content: center;
              background: #06d9b1;
            }
            .unchecked_item {
              background: #f1f2f4;
            }
            &_header {
              margin: 0;
            }
          }
          .sub_title {
            margin-top: -2px;
          }

          .sub_titles {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .remark {
        justify-content: flex-start !important;
        margin-left: 20%;
        width: 74% !important;
      }
      .header {
        margin-top: 30px;
        height: 20px !important;
      }
    }
  }
}
.collaborator_name {
  height: 28px;
  height: 14px !important;
  width: 150px !important;
  border-radius: 3px;
  margin-top: 10px;
}

.item_fetching {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 19%;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  margin: 0px 5px 10px;
  height: fit-content;
  padding: 20px 18px;

  .avatar {
    width: 65px;
    height: 65px;
    margin: auto;
    border: 1px white solid;
    border-radius: 50%;
  }

  .infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 11px;
    border-bottom: 1px solid #e1e4e8;
    width: 100%;

    span {
      width: 40px;
      height: 18px;
      &:first-child {
        width: 126px;
      }

      &:nth-child(2) {
        font-weight: 700;
        font-size: 16px;
        color: #29394d;
      }
    }
  }

  .counters {
    display: flex;
    height: 60px;
    .counter {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;

      span {
        height: 18px;
        width: 84px;

        &:first-child {
          width: 30px;
          margin-bottom: 10px;
        }
      }
      &:first-child {
        border-right: 1px solid #e1e4e8;
      }
    }
  }
}

.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}

.bonuses_table {
  background: #ffffff;
  padding: 0 20px;
  .labled-tag {
    height: 30px;
  }
  .matrix_title {
    .tag-bottom {
      opacity: 0;
      display: block;
      svg {
        transform: rotate(135deg);
        margin-right: 3px;
      }
    }
  }
  .CustumedMatrix_matrix_body_line__11lbb {
    &:hover {
      background: #f8f9fa;
      cursor: pointer;
      .action_doh {
        opacity: 1 !important;
      }
    }
    > div {
      &:first-child {
        min-width: 18% !important;
        height: 60px;
      }
    }

    span {
      width: 53px !important;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: normal;
      margin-bottom: 4px;
    }
    & > div {
      padding: 10px 5px 0px;
    }
  }
  .tag {
    margin: 8px 0;
    padding: 0px 15px 0px 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    border-left: 1px solid #e1e4e8;
    color: #6d7f92;
    position: relative;

    > div {
      &:first-child {
        // margin: 0px 5px;
        width: 58px !important;
        height: 28px;
        border-radius: 5px;
        padding: 6px 5px;
        background: #f1f2f4;
        text-align: end;
      }
    }

    .notif-pop {
      width: 8px;
      height: 8px;
      background: #de4848;
      border-radius: 4px;
      position: absolute;
      top: -3px;
      right: 17px;
    }

    input {
      height: 23px;
      font-size: 12px;
      line-height: 14px;
      color: #3c4e64;
      box-shadow: none;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #b2bcc6;
      border-radius: 4px;
      text-align: right;
      margin-bottom: 3px;
      width: 72px;
    }

    .progress_item {
      display: flex;
      background: #ffffff;
      border: 1px solid #f1f2f4;
      border-radius: 5px;
      margin-bottom: 4px;
      height: 112px;
      padding: 16px 26px;
      position: relative;

      &_progress {
        width: 200px;
        padding: 0px 18px;
        &_info {
          display: flex;
          justify-content: space-between;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          color: #6d7f92;
        }

        &_container {
          width: 100%;
          height: 4px;
          border-radius: 3px;
          background: rgba(109, 127, 146, 0.3);
        }
        &_bar {
          height: 4px;
          background: #29394d;
          border-radius: 3px;
        }
        &_index {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Roboto";
          flex-direction: column;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          svg {
            transform: rotate(180deg);
          }
          .realized_progress {
            text-align: center;
            width: 83px;
          }
        }
      }
    }
  }
  input {
    width: 52px !important;
  }
}
