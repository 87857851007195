.count_item {
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
  & p {
    padding: 6px;
    font-weight: normal;
    line-height: 14px;
    background-color: none;

    display: flex;
    align-items: center;
    gap: 7px;
  }
}
$cd-filter-width: 340px;

#ttp-horizontal-filter {
  padding-bottom: 5px;
  margin: 10px 0;
  border-bottom: 1px solid #f1f2f4;
  margin-left: 25px;

  select {
    text-transform: uppercase;
  }

  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    transition: all 0.3s;
    padding-right: 20px;

    .portal-link {
      border-left: 1px solid #f1f2f4;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      color: rgba($color: #3c4e64, $alpha: .9);
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .filter-button {
      width: 130px;
      height: 32px;
      transition: all 0.3s;
      background: #f1f2f4;
      border-radius: 30px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #29394d;
      //   color: $pickled-bluewood;
      text-transform: uppercase;
      display: flex;
      justify-content: space-around;
      align-items: center;
      outline: none;
      cursor: pointer;

      .filter-icon * {
        fill: #6d7f92;
      }

      .icon {
        margin-left: 1.5rem;
        font-size: inherit;
      }
    }
  }
  .filter-form {
    display: flex;
    align-items: baseline;
    text-transform: uppercase;
    margin: auto;
    border-radius: 4px 0px 4px 4px;
    // box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    position: relative;
    padding: 0 0 3rem 0;
    z-index: 2;
    margin-bottom: 2rem;
    margin-top: 12px;

    &-enter {
      opacity: 0;
      transform: translateY(-2.5rem);
    }

    &-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms, height 0.3s;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transform: translateY(-2.5rem);
      transition: opacity 300ms, transform 300ms, 0.3s;
    }

    span {
      position: relative;
      display: inline-block;
    }

    .close-button {
      position: absolute;
      right: 0;
      font-size: 1.2em;
      line-height: 1;
      background-color: white;
      transform: translate(-50%, -50%);
      top: 50%;
    }

    .ttp-select {
      margin-left: 5px;
      width: 285px;
      span[aria-live="assertive"] {
        display: none;
      }
    }
  }
  label {
    // font-family: $main-body-font;
    margin-top: 0.5rem;
    font-weight: 300;
    font-size: 14px;
    // line-height: 16px;
    text-transform: none;

    font-family: Roboto, "Segoe UI", Helvetica, sans-serif;
    font-size: 14px;
    text-transform: none;
    span.lbl {
      font-weight: 300;
      margin: 10px 0 0 0;
      //   padding: 9px;
    }
  }

  &.is-fixed {
    .filter-wrapper {
      position: fixed;
      background: #2495e1;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border-radius: 0;
    }
    &.ttp-medium-9 {
      .filter-wrapper {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
  .filter-close {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background: #f1f2f4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    z-index: -1;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &.ttp-horizontal-filter__open {
    .filter-button {
      background-color: #2495e1;
      color: #ffffff;

      .filter-icon * {
        fill: #ffffff;
      }
    }

    .filter-form {
      background: #fafbfb;
    }

    .empty-box .ttp-select__control {
      background-color: #fff;
      width: 250px;
    }

    &.light .filter-form {
      background-color: #fff;
      .empty-box .ttp-select__control {
        background: #fafbfb;
        width: 250px;
        margin-left: 25px;
      }
    }
  }
}

.empty-client-alert {
  background-color: #fff;
  color: #ffb300;
  padding: 3px 7px;
  width: 200px;
  border-radius: 5px;
  font-size: 12px;
  text-transform: capitalize;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  // triangle
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ffb300;
  }

  p {
    width: 100%;
    text-align: center;
  }
}
